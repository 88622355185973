import React, {
  useState,
  useEffect
} from "react";
import {
  useStitchAuth
} from "../../App/StitchAuth";
import { app } from "../../MongoStitch/app";
import {
  clients,
  users,
  orders
} from "../../MongoStitch";
import {
  BSON
} from 'mongodb-stitch-browser-sdk';
import PaymentPage from "../../Square/paymentPage"

export default function BillingInfo(props) {
  const [clubData, setClubData] = useState({ club: {} });
  const [inputText, setInputText] = useState({});
  const [clubSelection, setClubSelection] = useState({});
  const [inputTextShipping, setInputTextShipping] = useState({});
  const [customerId, setCustomerId] = useState();
  const [deliveryState, setDeliveryState] = useState(true)
  const [billingState, setBillingState] = useState(true)
  const [shippingState, setShippingState] = useState(true)
  const [cardSuccess, setCardSuccess] = useState(false)
  const [error, setError] = useState({})
  const [spinner, setSpinner] = useState(false)
  const [createMemberState, setCreateMemberState] = useState(false)
  const [initLoadStatus, setInitLoadStatus] = useState(0);
  const {
    actions,
    currentUser
  } = useStitchAuth();
  const [mailchimpList, setMailchimpList] = useState({});
  const [initStatus, setInitStatus] = useState({});

  useEffect(() => {
    if (props.userData[0] !== undefined && props.clubData.selected !== undefined) { //check to see if the user data was passed to page
      retrieveSqCustomer()
      findStates()
      props.pullCustomOrders()
    }
  }, [props.userData]);


  useEffect(() => {
    if (clubData) {
      if (clubData.clubs) {
        setMailchimpList(mailchimpList => (clubData.clubs.find(element => element._id.toString() === inputText.club_id)));
      }
    }
  }, [clubData, inputText.club_id]);

  const retrieveSqCustomer = async () => {
    await app.callFunction("SQUARE_Customers", ['retrieveCustomer', { clubId: props.clubData.selected.club_id, "sq_refId": props.userData[0].id }]).then(result => {
      switch (result.status) {
        case 400:
          alert('Something seems to have gone wrong.  If this continues, please try logging out and back in again.');
          break;
        case 410:
          alert('ERROR: ' + result.text);
          break;
        case 200: {
          if (result.data.customer.address !== undefined) {
            setInputText({
              ...result.data.customer,
              ...result.data.customer.address,
              club_id: props.userData[0].club_id,
              club_name: props.clubData.selected.club_name,
              club_pickupShip: props.userData[0].club_pickupShip,
              club_status: props.userData[0].club_status,
            })
            findInitClub(props.userData[0].club_id)
          }
          if (result.data.customer.address === undefined) {
            setInputText({ ...result.data.customer, club_id: props.userData[0].club_id })
          }
          if (props.userData[0].shipAddress) {
            setInputTextShipping(props.userData[0].shipAddress)
            setShippingState(true)
          }
          if (result.data.customer.cards !== null) {
            setCardSuccess(true)
          }
          setInitLoadStatus(1)
        }
          break;
        default:
          alert('Uh Oh! Something seems to have gone wrong.  Please try again.')
      }
    })
      .catch(err => alert('Uh Oh! Something seems to have gone wrong connecting to the server.  Please try again.'))

  }

  const submitForm = async () => {
    setBillingState(true)
    setSpinner(true);
    app.callFunction("SQUARE_Customers", ["updateMember", {
      clubId: props.clubData.selected.club_id,
      formData: inputText,
      shipData: inputTextShipping,
      sq_refId: props.clubData.selected.member_id
    }]).then(result => {
      switch (result.status) {
        case 400:
          alert('Uh Oh! Something seems to have gone wrong connecting to Square.  If this continues, please try logging out and back in again.');
          break;
        case 410:
          alert('ERROR: ' + result.text); //Vincipia error based on mongo return
          break;
        case 200: setSpinner(false);
          switch (true) {

            case inputText.club_id.toString() === initStatus.club_id.toString():
              {
                alert('Successfully Saved Your Changes')
              }
              break;
            default:
              {
                if (initStatus.mailchimpList) {
                  app.callFunction("SQUARE_Customers", ['moveMember', {
                    listIdOld: initStatus.mailchimpList.mailchimpList,
                    mailchimpList: mailchimpList.mailchimpList,
                    email_address: inputText.email_address,
                    given_name: inputText.given_name,
                    family_name: inputText.family_name,
                    phone_number: inputText.phone_number,
                    clubId: props.clubData.selected.club_id,
                    pid: clubData._id.toString()
                  }])
                }
                alert('Successfully Saved Your Changes')
              }
          }
          break;
        default:
          alert('Uh Oh! Something seems to have gone wrong.  Please try again.')
      }
    })
      .catch(err => alert('Uh Oh! Something seems to have gone wrong connecting to the server.  Please try again.'))
  }

  const findStates = async () => {
    const agg = [{
      $match: {
        "clubs._id": new BSON.ObjectId(props.clubData.selected.club_id)
      }
    }, {
      $project: {
        _id: 1,
        'activeStates': {
          '$filter': {
            'input': '$stateCompliance',
            'as': 'state',
            'cond': {
              '$eq': [
                '$$state.offSite', true
              ]
            }
          }
        },
        'club': {
          '$filter': {
            'input': '$clubs',
            'as': 'club',
            'cond': {
              '$eq': [
                '$$club._id', new BSON.ObjectId(props.clubData.selected.club_id)
              ]
            }
          }
        },
        clubs: 1,
        producerName: 1,
        stateCompliance: 1
      }
    }, {
      $unwind: {
        path: '$club',
      }
    }]
    clients.aggregate(agg).asArray()
      .then(data => {
        setClubData(data[0])

        setInitStatus({ club_id: data[0].club._id, mailchimpList: data[0].clubs.find(element => element._id.toString() === data[0].club._id.toString()) })


      })
      .catch(err => console.error(`Failed to find and update document: ${err}`))
      .catch(err => alert(`${err}`))
  }

  const handleInput = e => {
    e.persist();
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (e.target.options) {
      if (e.target.options[e.target.selectedIndex].dataset.name) {
        setInputText({
          ...inputText, club_name: e.target.options[e.target.selectedIndex].dataset.name
        });

      }
    }
    switch (e.target.name) {
      default:
        setInputText(inputText => ({
          ...inputText,
          [e.target.name]: value
        }));
    }
  }

  const handleInputClubChoice = async (e) => {
    e.persist();

    const pipeline = [{
      '$match': {
        'clubs._id': new BSON.ObjectId(e.target.value)
      }
    }, {
      '$project': {
        '_id': 0,
        'clubs': 1
      }
    }, {
      '$unwind': {
        'path': '$clubs',
        'preserveNullAndEmptyArrays': false
      }
    }, {
      '$match': {
        'clubs._id': new BSON.ObjectId(e.target.value)
      }
    }, {
      '$project': {
        'club': {
          'pickup': '$clubs.pickup',
          'shipping': '$clubs.shipping'
        }
      }
    }];


    await clients.aggregate(pipeline).asArray()
      .then(data => {
        setClubSelection(data[0].club)
      })
      .catch(err => alert(`${err}`))
  }

  const findInitClub = async (clubId) => {

    const pipeline = [{
      '$match': {
        'clubs._id': new BSON.ObjectId(clubId)
      }
    }, {
      '$project': {
        '_id': 0,
        'clubs': 1
      }
    }, {
      '$unwind': {
        'path': '$clubs',
        'preserveNullAndEmptyArrays': false
      }
    }, {
      '$match': {
        'clubs._id': new BSON.ObjectId(clubId)
      }
    }, {
      '$project': {
        'club': {
          'pickup': '$clubs.pickup',
          'shipping': '$clubs.shipping'
        }
      }
    }];


    await clients.aggregate(pipeline).asArray()
      .then(data => {
        setClubSelection(data[0].club)
      })
      .catch(err => alert(`${err}`))
  }

  const handleInputShipping = e => {
    e.persist();
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    switch (e.target.name) {
      default:
        setInputTextShipping(inputTextShipping => ({
          ...inputTextShipping,
          [e.target.name]: value
        }));
    }
  }

  const handleInputShippingSameAsBilling = e => {
    e.persist();
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    switch (value) {
      case true:
        setInputTextShipping({
          ...inputText
        })
        break;
      case false:
        {
          for (const [key, value] of Object.entries(inputTextShipping)) {
            setInputTextShipping(inputTextShipping => ({
              ...inputTextShipping,
              [key]: ""
            }));
          }
        }
        break;
      default:
        return
    }
  }

  const validation = () => {
    setError({})
    let etmpObj = inputText
    let i = 0

    for (const property in etmpObj) {
      if (etmpObj.given_name === null || etmpObj.given_name === undefined || etmpObj.given_name.trim() === '') {
        setError(error => ({
          ...error,
          given_name: "Field Required"
        }))
        i++
      }
      if (etmpObj.family_name === null || etmpObj.family_name === undefined || etmpObj.family_name.trim() === '') {
        setError(error => ({
          ...error,
          family_name: "Field Required"
        }))
        i++
      }
      if (etmpObj.email_address === null || etmpObj.email_address === undefined || etmpObj.email_address.trim() === '') {
        setError(error => ({
          ...error,
          email_address: "Field Required"
        }))
        i++
      }
      if (etmpObj.address_line_1 === null || etmpObj.address_line_1 === undefined || etmpObj.address_line_1.trim() === '') {
        setError(error => ({
          ...error,
          address_line_1: "Field Required"
        }))
        i++
      }
      if (etmpObj.administrative_district_level_1 === null || etmpObj.administrative_district_level_1 === undefined || etmpObj.administrative_district_level_1.trim() === '') {
        setError(error => ({
          ...error,
          administrative_district_level_1: "Field Required"
        }))
        i++
      }
      if (etmpObj.locality === null || etmpObj.locality === undefined || etmpObj.locality.trim() === '') {
        setError(error => ({
          ...error,
          locality: "Field Required"
        }))
        i++
      }
      if (etmpObj.postal_code === null || etmpObj.postal_code === undefined || etmpObj.postal_code.trim() === '') {
        setError(error => ({
          ...error,
          postal_code: "Field Required"
        }))
        i++
      }
      if (etmpObj.phone_number === null || etmpObj.phone_number === undefined || etmpObj.phone_number.trim() === '') {
        setError(error => ({
          ...error,
          phone_number: "Field Required"
        }))
        i++
      }
    }
    if (i > 0) {
      setBillingState(false)
      return null
    } else {
      setBillingState(!billingState)
      // submitForm()
    }
  };

  const validation2 = () => {
    setError({})
    let etmpObjShipAddress = inputTextShipping
    let i = 0

    for (const property in etmpObjShipAddress) {

      if (etmpObjShipAddress.given_name === null || etmpObjShipAddress.given_name === undefined || etmpObjShipAddress.given_name.trim() === '') {
        setError(error => ({
          ...error,
          given_nameShipAddress: "Field Required"
        }))
        i++
      }
      if (etmpObjShipAddress.family_name === null || etmpObjShipAddress.family_name === undefined || etmpObjShipAddress.family_name.trim() === '') {
        setError(error => ({
          ...error,
          family_nameShipAddress: "Field Required"
        }))
        i++
      }
      if (etmpObjShipAddress.email === null || etmpObjShipAddress.email === undefined || etmpObjShipAddress.email.trim() === '') {
        setError(error => ({
          ...error,
          emailShipAddress: "Field Required"
        }))
        i++
      }
      if (etmpObjShipAddress.address_line_1 === null || etmpObjShipAddress.address_line_1 === undefined || etmpObjShipAddress.address_line_1.trim() === '') {
        setError(error => ({
          ...error,
          address_line_1ShipAddress: "Field Required"
        }))
        i++
      }
      if (etmpObjShipAddress.administrative_district_level_1 === null || etmpObjShipAddress.administrative_district_level_1 === undefined || etmpObjShipAddress.administrative_district_level_1.trim() === '') {
        setError(error => ({
          ...error,
          administrative_district_level_1ShipAddress: "Field Required"
        }))
        i++
      }
      if (etmpObjShipAddress.locality === null || etmpObjShipAddress.locality === undefined || etmpObjShipAddress.locality.trim() === '') {
        setError(error => ({
          ...error,
          localityShipAddress: "Field Required"
        }))
        i++
      }
      if (etmpObjShipAddress.postal_code === null || etmpObjShipAddress.postal_code === undefined || etmpObjShipAddress.postal_code.trim() === '') {
        setError(error => ({
          ...error,
          postal_codeShipAddress: "Field Required"
        }))
        i++
      }
      if (etmpObjShipAddress.phone_number === null || etmpObjShipAddress.phone_number === undefined || etmpObjShipAddress.phone_number.trim() === '') {
        setError(error => ({
          ...error,
          phone_numberShipAddress: "Field Required"
        }))
        i++
      }
    }
    if (i > 0) {
      setShippingState(false)
      return null
    } else {
      setShippingState(true)
    }
  };

  const handleCard = async (newValue) => {
    if (newValue.result.status === 200) {
      setCardSuccess(newValue.field)
      setInputText({ ...inputText, cards: [newValue.result.data.card] })
    }
  }


  const privateClubs = () => {
    return (
      clubData.clubs.map((data, index) => {
        switch (data.private) {
          case false: {
            return (
              <>
                <option value={data._id} data-name={data.name}>{data.name}</option>
              </>
            )
          }
          case true: return
          default: {
            return (
              <>
                <option value={data._id} data-name={data.name}>{data.name}</option>
              </>
            )
          }
        }
      }))
  }

  const deliveryOptions = () => {
    if (props.userData !== undefined) {
      switch (deliveryState) {
        case true:
          return (
            <>
              <span className="js-state-select-label wsite-checkout-form__label">Club: {inputText.club_name}</span>
              <span className="js-state-select-label wsite-checkout-form__label">Delivery Option: {inputText.club_pickupShip}</span>
            </>
          )
          break;
        default:
          return (
            <>
              <div className="wsite-checkout-form__row ">
                <label className="wsite-checkout-form__field wsite-checkout-form__field--state"> <span className="js-state-select-label wsite-checkout-form__label">Club Selection</span>
                  <div className="wsite-checkout-form__select-wrap">
                    <select className="js-state-selection wsite-checkout-form__select" name="club_id" value={inputText.club_id} onChange={(e) => { handleInput(e); handleInputClubChoice(e) }}>
                      {privateClubs()}
                    </select>
                  </div>
                  <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="pickupShip"></div>
                </label>
              </div>
              <div className="wsite-checkout-form__row ">
                <label className="wsite-checkout-form__field wsite-checkout-form__field--state"> <span className="js-state-select-label wsite-checkout-form__label">How would you like to recieve your membership?</span>
                  <div className="wsite-checkout-form__select-wrap">
                    <select className="js-state-selection wsite-checkout-form__select" name="club_pickupShip" value={inputText.club_pickupShip} onChange={(e) => { handleInput(e) }}>
                      {deliveryChoicePickup()}
                      {deliveryChoiceShip()}
                    </select>
                  </div>
                  <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="pickupShip"></div>
                </label>
              </div>
            </>
          )
      }
    }
  }
  const deliveryChoiceShip = () => {
    switch (clubSelection.shipping) {
      case false: return;
        break;
      case true: return (
        <option value="Ship">Ship</option>
      )
        break;
      default: {
        return
      }
    }
  }

  const deliveryChoicePickup = () => {
    switch (clubSelection.pickup) {
      case false: return;
        break;
      default: {
        return (
          <option value="Pickup">Pickup</option>
        )
      }
    }
  }

  const customClub = () => {
    return (
      <>
        <br></br>
        <button className="btn btn-primary mt-3 text-white" onClick={(e) => { props.showHideModal() }}><small>Customize Order</small></button>
      </>
    )
  }
  const states = () => {
    if (clubData.stateCompliance !== undefined) {
      switch (inputText.club_pickupShip) {
        case null:
          return
          break;
        case undefined:
          return
          break;
        case "":
          return
          break;
        case "Pickup":
          switch (deliveryState) {
            case true: return (
              <>
                <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0">
                  <span className="wsite-button-inner">
                    <div className="wsite-next-button-label" onClick={() => { setDeliveryState(!deliveryState) }}>Change Selections</div>
                  </span>
                </button>
                {
                  props.customActive ?
                    customClub()
                    :
                    null
                }
                <div className="js-form-invalid-text wsite-checkout-form__invalid-text" style={{ "margin-bottom": "40px" }}></div>
              </>
            )
              break;
            default:
              return (
                <section className="wsite-checkout-payment__section wsite-checkout-proceed js-shipping-information-actions">
                  <fieldset className="wsite-checkout-form__fieldset">
                    <div className="wsite-checkout-spinner wsite-checkout-spinner--small is-hidden js-checkout-spinner"></div>
                    <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0" style={{ "margin-bottom": "40px" }}>
                      <span className="wsite-button-inner">
                        <div className={`wsite-next-button-label ${spinner ? "loading" : ""}`} onClick={() => { setDeliveryState(!deliveryState) }}>Confirm</div>
                      </span>
                    </button>
                  </fieldset>
                </section>
              )
          }
          break;
        default:
          switch (deliveryState) {
            case true: return (<><span className="js-state-select-label wsite-checkout-form__label">State: {inputText.administrative_district_level_1}</span>
              <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0">
                <span className="wsite-button-inner">
                  <div className="wsite-next-button-label" onClick={() => { setDeliveryState(!deliveryState); }}>Change Selections</div>
                </span>
              </button>
              {
                  props.customActive ?
                    customClub()
                    :
                    null
                }
              <div className="js-form-invalid-text wsite-checkout-form__invalid-text" style={{ "margin-bottom": "40px" }}></div>
            </>)
              break;
            default:

              return (
                <>
                  <div className="wsite-checkout-form__row ">
                    <label className="wsite-checkout-form__field wsite-checkout-form__field--state"> <span className="js-state-select-label wsite-checkout-form__label">Please Select Your State <small>(Only states we ship to will be visable)</small></span>
                      <div className="wsite-checkout-form__select-wrap">
                        <select className="js-state-selection wsite-checkout-form__select" name="administrative_district_level_1" value={inputText.administrative_district_level_1} onChange={handleInput}>
                          <option></option>
                          {clubData.activeStates.map((data, index) => (
                            <option value={data.name}>{data.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="administrative_district_level_1">{error.administrative_district_level_1}</div>
                    </label>
                  </div>
                  <section className="wsite-checkout-payment__section wsite-checkout-proceed js-shipping-information-actions">
                    <fieldset className="wsite-checkout-form__fieldset">
                      <div className="wsite-checkout-spinner wsite-checkout-spinner--small is-hidden js-checkout-spinner"></div>
                      <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0" style={{ "margin-bottom": "40px" }}>
                        <span className="wsite-button-inner">
                          <div className="wsite-next-button-label" onClick={() => {
                            switch (inputText.administrative_district_level_1) {
                              case null: setError({ administrative_district_level_1: "Required" })
                                break;
                              case undefined: setError({ administrative_district_level_1: "Required" })
                                break;
                              case "": setError({ administrative_district_level_1: "Required" })
                                break;
                              default: setDeliveryState(!deliveryState); setError({})
                            }
                          }}>Confirm</div>
                        </span>
                      </button>
                    </fieldset>
                  </section>
                </>
              )
          }
      }

    }
  }


  const allStates = () => {
    if (clubData.stateCompliance !== undefined) {
      switch (inputText.club_pickupShip) {
        case "Ship":
          return (
            <>
              <select className="js-state-selection wsite-checkout-form__select" name="administrative_district_level_1" value={inputText.administrative_district_level_1} onChange={handleInput}>
                <option>{inputText.administrative_district_level_1}</option>
              </select>
            </>
          )
          break;
        case "Pickup":
          return (
            <>
              <select className="js-state-selection wsite-checkout-form__select" name="administrative_district_level_1" value={inputText.administrative_district_level_1} onChange={handleInput}>
                <option></option>
                {clubData.stateCompliance.map((data, index) => (
                  <option value={data.name}>{data.name}</option>
                ))}
              </select>
            </>
          )
          break;
        default:
          return
      }
    }
  }

  const billingInfo = () => {
    if (props.userData !== undefined) {
      switch (billingState) {
        case false:
          return billingNoId()
          break;
        case true:
          return (
            <>
              {billingWithId()}
              <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0" style={{ "marginBottom": "40px" }} >
                <span className="wsite-button-inner">
                  <div className="wsite-next-button-label" onClick={() => setBillingState(!billingState)}>Update</div>
                </span>
              </button>
            </>
          )
          break;
      }
    }
  }

  const shippingInfo = () => {
    if (props.userData !== undefined) {
      switch (inputText.club_pickupShip) {
        case "Pickup": return
          break;
        case "Ship":
          switch (shippingState) {
            case true: return (
              <>
                {shippingComplete()}
                <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0" style={{ "marginBottom": "40px" }} >
                  <span className="wsite-button-inner">
                    <div className="wsite-next-button-label" onClick={() => setShippingState(!shippingState)}>Update</div>
                  </span>
                </button>
              </>
            )
              break;
            case false:

              return (
                <>
                  {shipping()}
                  <section className="wsite-checkout-payment__section wsite-checkout-proceed js-shipping-information-actions">
                    <fieldset className="wsite-checkout-form__fieldset">
                      <div className="wsite-checkout-spinner wsite-checkout-spinner--small is-hidden js-checkout-spinner"></div>
                      <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0">
                        <span className="wsite-button-inner">
                          <div className={`wsite-next-button-label ${spinner ? "loading" : ""}`} onClick={() => { validation2() }}>Confirm</div>
                        </span>
                      </button>
                    </fieldset>
                  </section>
                </>
              )
              break;
          }
          break;
      }

    }
  }

  const billingWithId = () => { // when a customer id has been created display this
    return (
      <>
        <header className="wsite-checkout-payment__header">
          <div className="wsite-checkout-payment__container">
            <div className="js-address-header wsite-checkout-payment__header-title">
              Billing Information
            </div>
          </div>
        </header>
        <span className="wsite-checkout-form__label">Email Address: <span>{inputText.email_address}</span></span>
        <span className="wsite-checkout-form__label">Name: <span>{inputText.given_name} {inputText.family_name}</span></span>
        <span className="wsite-checkout-form__label">Address:</span>
        <span className="wsite-checkout-form__label">{inputText.address_line_1}</span>
        <span className="wsite-checkout-form__label">{inputText.address_line_2}</span>
        <span className="wsite-checkout-form__label">{inputText.locality}, {inputText.administrative_district_level_1}</span>
        <span className="wsite-checkout-form__label">{inputText.postal_code}</span>
        <span className="wsite-checkout-form__label">Phone: <span style={{ "marginBottom": "10px" }}>{inputText.phone_number}</span> </span>

      </>

    )
  }




  const billingNoId = () => { // when no customer id has been created show form
    return (
      <>
        <div className="wsite-checkout-payment__content">
          <div id="customer-accounts-app"></div>
          <section className="wsite-checkout-payment__section js-shipping-information-form">
            <header className="wsite-checkout-payment__header">
              <div className="wsite-checkout-payment__container">
                <div className="js-address-header wsite-checkout-payment__header-title">
                  Billing Information
                </div>
              </div>
            </header>
            <div className="wsite-checkout-payment__content">
              <form className="js-address-form wsite-checkout-form wsite-checkout-payment__form">
                <fieldset className="wsite-checkout-form__fieldset">
                  <div className="wsite-checkout-form__row">
                    <label className="wsite-checkout-form__field js-customer-email-label">
                      <span className="wsite-checkout-form__label">Email Address</span>
                      <div className="wsite-checkout-form__input wsite-checkout-form__email-input-wrapper js-checkout-form__email-input-modal-attach">
                        <input className="js-customer-email wsite-checkout-form__email-input is-invalid" type="text" name="email_address" value={inputText.email_address} onChange={handleInput} autocapitalize="off" autocomplete="email">
                        </input>
                        <span className="wsite-checkout-form__email-enter-code js-customer-email-enter-code">Enter code</span>
                      </div>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="email">{error.email}</div>

                    </label>
                  </div>
                </fieldset>
                <fieldset className="wsite-checkout-form__fieldset">
                  <div className="wsite-checkout-form__row">
                    <label className="wsite-checkout-form__field">
                      <span className="wsite-checkout-form__label">First Name</span>
                      <input className="wsite-checkout-form__input" type="text" name="given_name" value={inputText.given_name} onChange={handleInput} autocomplete="given-name"></input>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="given_name">{error.given_name}</div>
                    </label>
                    <label className="wsite-checkout-form__field">
                      <span className="wsite-checkout-form__label">Last Name</span>
                      <input className="wsite-checkout-form__input" type="text" name="family_name" value={inputText.family_name} onChange={handleInput} autocomplete="family-name"></input>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="family_name">{error.family_name}</div>
                    </label>
                  </div>
                  <div className="wsite-checkout-form__row">
                    <label className="wsite-checkout-form__field">
                      <span className="wsite-checkout-form__label">Address Line 1</span>
                      <input className="wsite-checkout-form__input" type="text" name="address_line_1" value={inputText.address_line_1} onChange={handleInput} ></input>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="address_line_1">{error.address_line_1}</div>
                    </label>
                  </div>
                  <div className="wsite-checkout-form__row">
                    <label className="wsite-checkout-form__field">
                      <span className="wsite-checkout-form__label">Address Line 2</span>
                      <input className="wsite-checkout-form__input" type="text" name="address_line_2" value={inputText.address_line_2} onChange={handleInput}></input>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="address_line_2">{error.address_line_2}</div>
                    </label>
                  </div>
                  <div className="wsite-checkout-form__row">
                    <label className="wsite-checkout-form__field">
                      <span className="wsite-checkout-form__label">City</span>
                      <input className="wsite-checkout-form__input" type="text" name="locality" value={inputText.locality} onChange={handleInput} ></input>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="locality">{error.locality}</div>
                    </label>
                  </div>
                  <div className="wsite-checkout-form__row ">
                    <label className="wsite-checkout-form__field wsite-checkout-form__field--state"> <span className="js-state-select-label wsite-checkout-form__label">State</span>
                      <div className="wsite-checkout-form__select-wrap">
                        {allStates()}
                      </div>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="administrative_district_level_1">{error.administrative_district_level_1}</div>
                    </label>
                    <label className="wsite-checkout-form__field wsite-checkout-form__field--zipcode">
                      <span className="js-zip-code-label wsite-checkout-form__label">Zip Code</span>
                      <input className="js-zip-code-input wsite-checkout-form__input" type="text" name="postal_code" value={inputText.postal_code} onChange={handleInput} autocomplete="shipping postal-code"></input>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="postal_code">{error.postal_code}</div>
                    </label>
                  </div>
                  <div className="wsite-checkout-form__row">
                    <label className="wsite-checkout-form__field">
                      <span className="wsite-checkout-form__label">Country</span>
                      <div className="wsite-checkout-form__select-wrap">
                        <select className="js-shipping-address-country wsite-checkout-form__select" name="country" value={inputText.country} onChange={handleInput} autocomplete="shipping country">
                          <option value="US" selected="">
                            United States
                          </option>
                        </select>
                      </div>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="country"></div>
                    </label>
                  </div>
                  <div className="wsite-checkout-form__row ">
                    <label className="wsite-checkout-form__field wsite-checkout-form__field--phone_number-country-code wsite-checkout-js-checkout-phone_number-country-code">
                      <span className="wsite-checkout-form__label">
                        Phone Number
                      </span>
                      <div className="wsite-checkout-form__select-wrap">
                        <select className="wsite-checkout-form__select js-phone_number-country-code" name="phone_number_country_code" value={inputText.phone_number_country_code} onChange={handleInput}>
                          <option selected="" value="US">
                            United States +1
                          </option>
                        </select>
                      </div>
                    </label>
                    <label className="wsite-checkout-form__field wsite-checkout-form__field--phone_number wsite-checkout-js-checkout-phone_number-number ">
                      <span className="wsite-checkout-form__label">&nbsp;</span>
                      <input className="wsite-checkout-form__input" type="text" name="phone_number" value={inputText.phone_number} onChange={handleInput} autocomplete="tel"></input>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="phone_number">{error.phone_number}</div>
                    </label>
                  </div>
                </fieldset>
              </form>
            </div>
            <div className="js-read-only-address-administrative_district_level_1"></div>
            <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="shippingAddressGenericError"></div>
          </section>
          <section className="wsite-checkout-payment__section wsite-checkout-proceed js-shipping-information-actions">
            <fieldset className="wsite-checkout-form__fieldset">
              <div className="wsite-checkout-spinner wsite-checkout-spinner--small is-hidden js-checkout-spinner"></div>
              <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0" style={{ "marginBottom": "40px" }}>
                <span className="wsite-button-inner">
                  <div className={`wsite-next-button-label ${spinner ? "loading" : ""}`} onClick={() => { validation() }}>Confirm</div>
                </span>
              </button>
            </fieldset>
          </section>
        </div>
      </>
    )



  }



  const shipping = () => { // when no customer id has been created show form
    return (
      <>
        <div className="wsite-checkout-payment__content">

          <div id="customer-accounts-app"></div>
          <section className="wsite-checkout-payment__section js-shipping-information-form">
            <header className="wsite-checkout-payment__header">
              <div className="wsite-checkout-payment__container">
                <div className="js-address-header wsite-checkout-payment__header-title">
                  Shipping Information
                </div>
              </div>
            </header>
            <div className="wsite-checkout-payment__content">

              <label className="wsite-checkout-form__field js-customer-email-label" style={{ "marginBottom": "20px" }}>
                <span className="wsite-checkout-form__label"><input type="checkbox" onClick={handleInputShippingSameAsBilling}></input>  &nbsp; Set Shipping Same as Billing</span>
              </label>
              <form className="js-address-form wsite-checkout-form wsite-checkout-payment__form">
                <fieldset className="wsite-checkout-form__fieldset">
                  <div className="wsite-checkout-form__row">
                    <label className="wsite-checkout-form__field js-customer-email-label">
                      <span className="wsite-checkout-form__label">Email Address</span>
                      <div className="wsite-checkout-form__input wsite-checkout-form__email-input-wrapper js-checkout-form__email-input-modal-attach">
                        <input className="js-customer-email wsite-checkout-form__email-input is-invalid" type="text" name="email" value={inputTextShipping.email} onChange={handleInputShipping} autocapitalize="off" autocomplete="email">
                        </input>
                      </div>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="email">{error.emailShipAddress}</div>
                      <div id="customer-accounts-payment-login"></div>
                    </label>
                  </div>
                </fieldset>
                <fieldset className="wsite-checkout-form__fieldset">
                  <div className="wsite-checkout-form__row">
                    <label className="wsite-checkout-form__field">
                      <span className="wsite-checkout-form__label">First Name</span>
                      <input className="wsite-checkout-form__input" type="text" name="given_name" value={inputTextShipping.given_name} onChange={handleInputShipping} autocomplete="given-name"></input>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="given_name">{error.given_nameShipAddress}</div>
                    </label>
                    <label className="wsite-checkout-form__field">
                      <span className="wsite-checkout-form__label">Last Name</span>
                      <input className="wsite-checkout-form__input" type="text" name="family_name" value={inputTextShipping.family_name} onChange={handleInputShipping} autocomplete="family-name"></input>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="family_name">{error.family_nameShipAddress}</div>
                    </label>
                  </div>
                  <div className="wsite-checkout-form__row">
                    <label className="wsite-checkout-form__field">
                      <span className="wsite-checkout-form__label">Address Line 1</span>
                      <input className="wsite-checkout-form__input" type="text" name="address_line_1" value={inputTextShipping.address_line_1} onChange={handleInputShipping} ></input>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="address_line_1">{error.address_line_1ShipAddress}</div>
                    </label>
                  </div>
                  <div className="wsite-checkout-form__row">
                    <label className="wsite-checkout-form__field">
                      <span className="wsite-checkout-form__label">Address Line 2</span>
                      <input className="wsite-checkout-form__input" type="text" name="address_line_2" value={inputTextShipping.address_line_2} onChange={handleInputShipping}></input>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="address_line_2"></div>
                    </label>
                  </div>
                  <div className="wsite-checkout-form__row">
                    <label className="wsite-checkout-form__field">
                      <span className="wsite-checkout-form__label">City</span>
                      <input className="wsite-checkout-form__input" type="text" name="locality" value={inputTextShipping.locality} onChange={handleInputShipping} ></input>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="locality">{error.localityShipAddress}</div>
                    </label>
                  </div>
                  <div className="wsite-checkout-form__row ">
                    <label className="wsite-checkout-form__field wsite-checkout-form__field--state"> <span className="js-state-select-label wsite-checkout-form__label">Please Select Your State <small>(Only states we ship to will be visable)</small></span>
                      <div className="wsite-checkout-form__select-wrap">
                        <select className="js-state-selection wsite-checkout-form__select" name="administrative_district_level_1" value={inputTextShipping.administrative_district_level_1} onChange={handleInputShipping}>
                          <option></option>
                          {clubData.activeStates.map((data, index) => (
                            <option value={data.name}>{data.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="administrative_district_level_1">{error.administrative_district_level_1ShipAddress}</div>
                    </label>
                    <label className="wsite-checkout-form__field wsite-checkout-form__field--zipcode">
                      <span className="js-zip-code-label wsite-checkout-form__label">Zip Code</span>
                      <input className="js-zip-code-input wsite-checkout-form__input" type="text" name="postal_code" value={inputTextShipping.postal_code} onChange={handleInputShipping} autocomplete="shipping postal-code"></input>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="postal_code">{error.postal_codeShipAddress}</div>
                    </label>
                  </div>
                  <div className="wsite-checkout-form__row">
                    <label className="wsite-checkout-form__field">
                      <span className="wsite-checkout-form__label">Country</span>
                      <div className="wsite-checkout-form__select-wrap">
                        <select className="js-shipping-address-country wsite-checkout-form__select" name="country" value={inputTextShipping.country} onChange={handleInputShipping} autocomplete="shipping country">
                          <option value="US" selected="">
                            United States
                          </option>
                        </select>
                      </div>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="country"></div>
                    </label>
                  </div>
                  <div className="wsite-checkout-form__row ">
                    <label className="wsite-checkout-form__field wsite-checkout-form__field--phone_number-country-code wsite-checkout-js-checkout-phone_number-country-code">
                      <span className="wsite-checkout-form__label">
                        Phone Number
                      </span>
                      <div className="wsite-checkout-form__select-wrap">
                        <select className="wsite-checkout-form__select js-phone_number-country-code" name="phone_number_country_code" value={inputTextShipping.phone_number_country_code} onChange={handleInputShipping}>
                          <option selected="" value="US">
                            United States +1
                          </option>
                        </select>
                      </div>
                    </label>
                    <label className="wsite-checkout-form__field wsite-checkout-form__field--phone_number wsite-checkout-js-checkout-phone_number-number ">
                      <span className="wsite-checkout-form__label">&nbsp;</span>
                      <input className="wsite-checkout-form__input" type="text" name="phone_number" value={inputTextShipping.phone_number} onChange={handleInputShipping} autocomplete="tel"></input>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="phone_number">{error.phone_numberShipAddress}</div>
                    </label>
                  </div>
                </fieldset>
              </form>
            </div>
            <div className="js-read-only-address-administrative_district_level_1"></div>
            <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="shippingAddressGenericError"></div>
          </section>

        </div>
      </>
    )



  }

  const card = () => {
    if (inputText.cards !== undefined && inputText.cards !== null) {
      return (
        <>
          {inputText.cards.map((cards, index) => (
            <div className="customer__customer-object-list__object">
              <div className="customer__customer-object-list__lines">
                <div className="customer__customer-object-list__line">
                  Ends in {cards.last_4}
                </div>
                <div className="customer__customer-object-list__line--secondary">
                  Exp: {cards.exp_month} / {cards.exp_year}
                </div>
              </div>
              <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0">
                <span className="wsite-button-inner">
                  <div className="wsite-next-button-label" onClick={() => { setCardSuccess(!cardSuccess) }}>Update Card</div>
                </span>
              </button>
            </div>
          ))}
        </>
      )
    }
  }

  const paymentSelect = () => {
    switch (inputText.cards) {
      case undefined: return (<>
        <div className="row">
          <header className="wsite-checkout-payment__header">
            <div className="wsite-checkout-payment__container">
              <div className="js-address-header wsite-checkout-payment__header-title">
                Payment Information
              </div>
            </div>
          </header>

          <PaymentPage customerId={inputText.id} uid={String(clubData.club._id)} onChange={handleCard} />
          <p style={{ "marginBottom": "40px" }}>
            By clicking the Confirm Button I, {inputText.given_name} {inputText.family_name}, authorize {props.prodName} to charge my credit card above for agreed upon purchases related to my club membership. I understand that my information will be saved to file for future transactions on my account.
          </p>
        </div>

      </>

      )
        break;
      default:
        switch (cardSuccess) {
          case false:
            return (<>
              <header className="wsite-checkout-payment__header">
                <div className="wsite-checkout-payment__container">
                  <div className="js-address-header wsite-checkout-payment__header-title">
                    Payment Information
                  </div>
                </div>
              </header>

              <PaymentPage customerId={inputText.id} uid={String(clubData.club._id)} onChange={handleCard} card_id={inputText.cards[0].id} />
              <p style={{ "marginBottom": "40px" }}>
                By clicking the Confirm Button I, {inputText.given_name} {inputText.family_name}, authorize {props.prodName} to charge my credit card above for agreed upon purchases related to my club membership. I understand that my information will be saved to file for future transactions on my account.
              </p>
            </>
            )
            break;
          case true:
            return (
              <>
                <header className="wsite-checkout-payment__header">
                  <div className="wsite-checkout-payment__container">
                    <div className="js-address-header wsite-checkout-payment__header-title">
                      Payment Information

                      {card()}
                    </div>
                  </div>
                </header>
              </>
            )
          default: return
        }
    }

  }


  const shippingSelect = () => {
    if (shippingState === false) {
      return
    }
    else if (shippingState === true) {
      return (
        <>

          <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0">
            <span className="wsite-button-inner">
              <div className={`wsite-next-button-label ${spinner ? "loading" : ""}`} onClick={() => { setBillingState(!billingState) }}>Confirm</div>
            </span>
          </button>

        </>
      )
    }
  }

  const reviewSubmit = () => {
    if (clubData.stateCompliance !== undefined) {

      return (
        <>
          <section className="wsite-checkout-payment__section wsite-checkout-proceed js-shipping-information-actions">
            <fieldset className="wsite-checkout-form__fieldset">

              <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0" style={{ "backgroundColor": "#00ADA7" }}>
                <span className="wsite-button-inner">
                  <div className={`wsite-next-button-label ${spinner ? "loading" : ""}`} onClick={() => { validation(); validation2(); submitForm() }}>Save Changes</div>
                </span>
              </button>
            </fieldset>
          </section>
        </>
      )

    }
  }

  const shippingComplete = () => { // when a customer id has been created display this
    return (
      <>
        <header className="wsite-checkout-payment__header">
          <div className="wsite-checkout-payment__container">
            <div className="js-address-header wsite-checkout-payment__header-title">
              Shipping Information
            </div>
          </div>
        </header>
        <span className="wsite-checkout-form__label">Email Address: <span>{inputTextShipping.email_address}</span></span>
        <span className="wsite-checkout-form__label">Name: <span>{inputTextShipping.given_name} {inputTextShipping.family_name}</span></span>
        <span className="wsite-checkout-form__label">Address:</span>
        <span className="wsite-checkout-form__label">{inputTextShipping.address_line_1}</span>
        <span className="wsite-checkout-form__label">{inputTextShipping.address_line_2}</span>
        <span className="wsite-checkout-form__label">{inputTextShipping.locality}, {inputTextShipping.administrative_district_level_1}</span>
        <span className="wsite-checkout-form__label">{inputTextShipping.postal_code}</span>
        <span className="wsite-checkout-form__label">Phone: <span style={{ "marginBottom": "10px" }}>{inputTextShipping.phone_number}</span> </span>

      </>

    )
  }

  const ticketOrReceipt = () => {
    switch (createMemberState) {
      case true: return
        break;
      default:
        return (
          <>
            <div className="row">
              <div className="col-12 col-lg-6" style={{ "textAlign": "left" }}>
                {deliveryOptions()}
                {states()}
              </div>
              <div className="col-12 col-lg-6" style={{ "textAlign": "left" }}>
                {paymentSelect()}
              </div>
              <div className="col-12 col-lg-6" style={{ "textAlign": "left" }}>
                {billingInfo()}
              </div>
              <div className="col-12 col-lg-6" style={{ "textAlign": "left" }}>
                {shippingInfo()}
              </div>
            </div>
            <div className="row" style={{ "float": "left" }}>
              <div className="col" style={{ "textAlign": "left" }}>
                {reviewSubmit()}
              </div>
            </div>
          </>
        )
    }
  }
  if (initLoadStatus === 0) {
    return (
      <>
        <div className="row d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <span>Loading...</span>
        </div>
      </>
    )
  }
  return (
    <>
      {ticketOrReceipt()}
    </>
  )
}