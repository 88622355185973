import React, {
  useState,
  useEffect
} from "react";
import {
  useStitchAuth
} from "../App/StitchAuth";
import {
  BSON,
} from 'mongodb-stitch-browser-sdk';
import {
  clients,
  users,
  orders
} from "../MongoStitch";

import BillingInfo from "../Admin/MemberForm";
import './index.css'
import { updateOrder, getInventoryStatus, calcDifferenceInv, updateInventoryStatus } from "./mongoAdmin";

export default function Admin(props) {
  const [userData, setUserData] = useState({ memberships: [] });
  const [newUser, setNewUser] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [inputText, setInputText] = useState({});
  const [modalShow, setModalShow] = useState(false)
  const [customActive, setCustomActive] = useState(false)
  const [orderTotal, setOrderTotal] = useState(0)
  const [isStatusVisible, setIsStatusVisible] = useState({ status: 200, visible: false, text: "" })
  const [loading, setLoading] = useState(false)
  const { actions } = useStitchAuth();
  const [customOrderData, setCustomOrderData] = useState({
    allowableItems: [],
    customLI: []
  })
  var newCust = false

  useEffect(() => {
    if (forceUpdate === true && newUser === false) {
      findUserInfo()
    }
    if (newUser === false && forceUpdate === false || newUser === true && forceUpdate === false) {
      findUserInfo()
    }

  }, [newUser, forceUpdate]);

  const {
    isLoggedIn,
    actions: {
      handleLogout
    },
    currentUser
  } = useStitchAuth();

  const handleClubChoice = async e => {
    e.persist();
    if (e.target.options) {
      var member_id = e.target.options[e.target.selectedIndex].dataset.member_id;
      var producer_id = e.target.options[e.target.selectedIndex].dataset.winery_id;
      var club_id = e.target.options[e.target.selectedIndex].dataset.club_id;
      var club_name = e.target.options[e.target.selectedIndex].dataset.club_name;
    }
    else {
      var member_id = e.target.dataset.member_id;
      var producer_id = e.target.dataset.winery_id;
      var club_id = e.target.dataset.club_id;
      var club_name = e.target.dataset.club_name;
    }

    setUserData({
      ...userData,
      selected: {
        member_id,
        producer_id,
        club_id,
        club_name
      }
    })


    const pipeline = [{
      $match: {
        "id": producer_id
      }
    }, {
      $project: {
        _id: 0,
        member: {
          $filter: {
            input: "$members",
            as: "member",
            cond: {
              $eq: ["$$member.id", member_id]
            }
          }
        },
        "stateCompliance": 1
      }
    }, {
      $unwind: {
        path: "$member",
        preserveNullAndEmptyArrays: false
      }
    }, {
      $replaceRoot: {
        newRoot: {
          '$mergeObjects': ['$member', {
            states: '$stateCompliance'
          }]

        }
      }
    }]

    await clients.aggregate(pipeline).asArray()
      .then(data => {
        setInputText(data)
      })
      .catch(err => alert(`${err}`))
  };

  const showHideModal = () => {
    setModalShow(!modalShow)
  }

  const findUserInfo = () => {
    //pull all user information for all clubs they are members of across producers
    const agg = [{
      $match: {
        "UID": currentUser.id
      }
    }]
    users.aggregate(agg).asArray()
      .then(payload => {
        //upon login, first check to see if there is a user profile exisiting in the DB with the "UID": currentUser.id
        // if there is, just run find userInfo
        //if there isnt, findUserDataInit and create profile, then run findUserInfo()

        switch (payload[0]) {
          case undefined: newCust = true; findUserDataInit(newCust)
            break;
          //case ??: if existing, findUserDataInit() but pass variable to show it isnt a nw login - perhaps make this the default?

          //break;
          default:
            newCust = false
            findUserDataInit(newCust)

            var tempArray = [];
            for (const element of payload[0].memberIDs) { //loop through all member ID's to clubs registered with the user
              tempArray = [...tempArray, {
                "$eq": ["$$member._id", element] //add each ID to the conditional filter for the DB projection
              }]
            }
            const agg2 = [{
              $project: {
                member: {
                  $filter: {
                    input: "$members",
                    as: "member",
                    cond: {
                      $or: tempArray
                    }
                  }
                },
                clubs: 1,
                producerName: 1,
                producerID: "$_id"
              }
            }, {
              $unwind: {
                path: '$member',
                preserveNullAndEmptyArrays: false
              }
            }, {
              $project: {
                member: 1,
                clubs: 1,
                producerName: 1,
                producerID: 1,
                club_id: {
                  $toObjectId: "$member.club_id"
                }
              }
            }, {
              $project: {
                member: 1,
                producerName: 1,
                producerID: 1,
                club: {
                  $filter: {
                    input: '$clubs',
                    as: 'club',
                    cond: {
                      $eq: ["$$club._id", "$club_id"]
                    }
                  }
                },
              }
            }, {
              $unwind: {
                path: "$club"
              }
            }, {
              $project: {

                _id: 1,
                club_id: "$club._id",
                club_name: "$club.name",
                member_id: "$member.id",
                pickupShip: "$member.club_pickupShip",
                LTV: "$member.LTV",
                producerName: "$producerName",
                producerID: "$producerID"
              }
            }, {
              $group: {
                _id: "$_id",
                memberships: {
                  $push: '$$ROOT'
                }
              }
            }, {
              $unwind: {
                path: "$memberships",
                preserveNullAndEmptyArrays: false
              }
            }, {
              $group: {
                _id: new BSON.ObjectId(),
                memberships: {
                  $push: '$memberships'
                }
              }
            }]
            clients.aggregate(agg2).asArray()
              .then(payload2 => {
                setUserData(payload2[0])
              })
              .catch(err => console.error(`Failed to find documents: ${err}`))
              .catch(err => alert(`${err}`))
        }
      })
      .catch(err => console.error(`Failed to find documents: ${err}`))
      .catch(err => alert(`${err}`))
  }

  const userInfoRet = () => {
    if (userData) {
      if (userData.memberships) {
        return (
          <>
            <div className="col-lg-2 d-none d-lg-block justify-self-center leftNav" align="center">
              <h5 className="mt-3">Select your club</h5>
              <p style={{ padding: "12px" }}><small>If you dont see your club listed, please contact them directly to ensure they have your correct information on file</small></p>
              {userData.memberships.map((data, index) => (
                <>
                  <div>
                    <p key={data.id}><strong> {data.producerName}</strong></p>
                    <button className="vinButtonInverse" data-member_id={data.member_id} data-winery_id={data.producerID} data-club_id={data.club_id} data-club_name={data.club_name} onClick={handleClubChoice}>select</button>
                  </div>
                </>
              ))}
            </div>
            <div className="col-12 d-block d-lg-none justify-self-center leftNav" align="center">
              <select className="form-select" name="" onChange={handleClubChoice}>
                <option>Select your club</option>
                {userData.memberships.map((data, index) => (
                  <>
                    <option data-member_id={data.member_id} data-winery_id={data.producerID} data-club_id={data.club_id} data-club_name={data.club_name}>{data.producerName}</option>
                  </>
                ))}
              </select>
              <p> <small >If you dont see your club listed, please contact them directly to ensure they have your correct information on file</small></p>
            </div>
          </>
        )
      }
    }
    return (
      <>
        <div className="col-lg-2 d-none d-lg-block justify-self-center leftNav" align="center">
          <h5 className="mt-3">Select your club</h5>
          <p style={{ padding: "12px" }}><small>If you dont see your club listed, please contact them directly to ensure they have your correct information on file</small></p>
          <h5> You dont belong to any clubs </h5>
        </div>
        <div className="col-12 d-block d-lg-none justify-self-center leftNav" align="center">
          <small>If you dont see your club listed, please contact them directly to ensure they have your correct information on file</small>
          <h5> You dont belong to any clubs </h5>
        </div>
      </>
    )
  }

  const findUserDataInit = (newCust) => {
    var agg = [{
      $project: {
        _id: 1,
        member: {
          $filter: {
            input: '$members',
            as: 'member',
            cond: {
              $eq: [
                '$$member.email_address',
                currentUser.profile.data.email
              ]
            }
          }
        }
      }
    }, {
      $unwind: {
        path: '$member',
        preserveNullAndEmptyArrays: false
      }
    }, {
      $project: {
        memberIDs: '$member._id',
      }
    }, {
      $group: {
        _id: new BSON.ObjectID(),
        memberIDs: {
          $push: '$memberIDs'
        }
      }
    }]

    clients.aggregate(agg).asArray()
      .then(data => {
        switch (data[0].memberIDs) {
          case undefined: return
            break;

          default: {
            switch (newCust) {
              case true: {
                const newItem = {
                  UID: currentUser.id,
                  created_at: new Date(),
                  updated_at: new Date(),
                  given_name: "",
                  family_name: "",
                  reference_id: "",
                  preferences: {},
                  creation_source: "VinSipp",
                  memberIDs: data[0].memberIDs
                };

                users.insertOne(newItem)
                  .then(result => {
                    setNewUser(true)
                  })
                  .catch(err => console.error(`Failed to insert item: ${err}`))
              }
                break;
              case false: {
                const newItem = {
                  UID: currentUser.id,
                  created_at: new Date(),
                  updated_at: new Date(),
                  given_name: "",
                  family_name: "",
                  reference_id: "",
                  preferences: {},
                  creation_source: "Different",
                  memberIDs: data[0].memberIDs
                };

                users.updateOne({ UID: currentUser.id }, { ...newItem })
                  .then(result => {
                    setForceUpdate(true)
                  })
                  .catch(err => console.error(`Failed to update item: ${err}`))
              }
                break;
              default: return
            }

          }
        }

        //create a new user profile with "UID": currentUser.id and the membership ID's found as well as all the user info from the form
      })
      .catch(err => console.error(`Failed to find and update document: ${err}`))
      .catch(err => alert(`${err}`))

  }

  const userDataFromSelection = () => {
    if (userData) {
      if (userData.selected !== undefined) {
        return (
          <>
            <BillingInfo userData={inputText} clubData={userData} showHideModal={showHideModal} pullCustomOrders={pullCustomOrders} customActive={customActive} updateCustomOrder={updateCustomOrder} />
          </>
        )
      }
    }
  }

  const updateCustomOrder = async () => {
    var customHold = customOrderData

    updateOrder(customHold)
      .then(async res => {
        setLoading(false)
        if (res.status === 200) {
          if (customOrderData.inventoryUpdate === true) {
            var catalogObjectIds = calcDifferenceInv(customHold)
            await updateInventoryStatus({ pid: userData.selected.producer_id, catalogObjectIds: catalogObjectIds })
          }
          setCustomOrderData(customOrderData => ({
            ...customOrderData,
            initialLI: customHold.customLI
          }));

          setLoading(true)
          await pullCustomOrders()
          setIsStatusVisible({ status: 200, visible: true, color: "green", text: "Success!" })
          //timeout to hide after 3 seconds
          setTimeout(() => {
            setIsStatusVisible({ status: 200, visible: false, color: "", text: "" })
          }, 3000);
        }
        else {
          setIsStatusVisible({ status: 200, visible: true, color: "red", text: "An error occured!" })
          //timeout to hide after 3 seconds
          setTimeout(() => {
            setIsStatusVisible({ status: 200, visible: false, color: "", text: "" })
          }, 3000);
        }

      })
      .catch(err => {
        setLoading(false)
        setIsStatusVisible({ status: 200, visible: true, color: "red", text: "An error occured!" })
        //timeout to hide after 3 seconds
        setTimeout(() => {
          setIsStatusVisible({ status: 200, visible: false, color: "", text: "" })
        }, 3000);
      })

  }

  const inventoryMatch = (props) => {
    var tempAllowable = props.items.map(item => {
      switch (props.type) {
        case 1: {
          if (item.variation) {
            return props.invData.data.map(count => {
              var t3 = item.variation.map(variation => {
                if (count.catalogObjectId === variation.id) {
                  return { ...variation, count: count.quantity }
                }
                else return
              })
              var t4 = t3.filter(t4 => t4 !== undefined)
              return t4[0]
            })
          }
          else {
            return item
          }
        }
          break;
        case 2: {
          return props.invData.data.map(count => {
            if (count.catalogObjectId === item.uid) {
              return { ...item, count: count.quantity }
            }
            else return
          })
        }
          break;

      }
    })
    var t2 = tempAllowable.map(arr => {
      return arr.filter(arr2 => arr2 !== undefined)
    })
    return props.items.map(item => {
      switch (props.type) {
        case 1: {
          var find1 = t2.filter(t3 => {
            if (t3[0]) {
              return t3[0].itemVariationData.itemId === item.id
            }
          })
          if (find1[0] !== undefined) {
            return { ...item, variation: find1[0] }
          }
        }
          break;
        case 2: {
          var find1 = t2.filter(t3 => t3[0].uid === item.uid)
          if (find1[0] !== undefined) {
            return { ...find1[0][0] }
          }
        }
          break;
      }

    })
  }

  const pullCustomOrders = async (props) => {
    if (currentUser.profile.data.email && userData.selected) {
      const pipeline = [{
        $match: {
          pid: userData.selected.producer_id,
          customClub: true,
          "members.club_id": userData.selected.club_id,
          hasStartedProcessing: false,
          'members.email_address': currentUser.profile.data.email
        }
      }, {
        $project: {
          allowableItems: 1,
          club: 1,
          producer: 1,
          lockTicket: 1,
          processDate: "$fulfillment.shipDate",
          inventoryUpdate: "$fulfillment.inventory.update",
          minQty: '$fulfillment.minOrderQty',
          member: {
            $filter: {
              input: '$members',
              as: 'member',
              cond: {
                $eq: [
                  '$$member.email_address',
                  currentUser.profile.data.email
                ]
              }
            }
          }
        }
      }]

      await orders.aggregate(pipeline).asArray()
        .then(async data => {
          setLoading(false)
          if (data[0]) {
            console.log("data[0]", data[0]._id.toString())
            if (data[0]._id) {
              switch (data[0].inventoryUpdate) {
                case true: {
                  getInventoryStatus({ items: data[0].allowableItems, pid: userData.selected.producer_id })
                    .then(async invData => {
                      if (invData.status === 200) {
                        //match the invData.data id's to the allowableItemId's and add the count
                        var editedAllowable = await inventoryMatch({ items: data[0].allowableItems, invData: invData, type: 1 })
                        var editedLineI = await inventoryMatch({ items: data[0].member[0].customOrderData.lineItems, invData: invData, type: 2 })
                        setCustomActive(true)
                        var discount = subtotal(editedLineI) * (Number(data[0].club.discount.percentage) * .01)
                        var subTotal = subtotal(editedLineI) - discount
                        //calc initial orderTotal
                        var tempTot = 0
                        editedLineI.map(item => {
                          tempTot = tempTot + Number(item.quantity)
                        })
                        console.log("data[0].member[0].customOrderData.lineItems", data[0])
                        setOrderTotal(tempTot)
                        setCustomOrderData({
                          locationId: data[0].producer.locationId,
                          orderId: data[0]._id,
                          allowableItems: editedAllowable,
                          minQty: data[0].minQty,
                          customLI: editedLineI,
                          lockTicket: data[0].lockTicket,
                          memberId: data[0].member[0]._id,
                          processDate: data[0].processDate,
                          inventoryUpdate: data[0].inventoryUpdate,
                          subTotal: subTotal,
                          discountAmount: discount,
                          discount: Number(data[0].club.discount.percentage),
                          initialLI: data[0].member[0].customOrderData.lineItems,
                          member: data[0].member
                        })
                      }
                      else {
                        setCustomActive(false)
                      }
                    })
                    .catch(err => {
                      setLoading(false)
                      setCustomActive(false)
                    })
                }
                  break;
                default: {
                  var discount = subtotal(data[0].member[0].customOrderData.lineItems) * (Number(data[0].club.discount.percentage) * .01)
                  var subTotal = subtotal(data[0].member[0].customOrderData.lineItems) - discount
                  var tempTot = 0
                  data[0].member[0].customOrderData.lineItems.map(item => {
                    tempTot = tempTot + Number(item.quantity)
                  })
                  setCustomActive(true)
                  setOrderTotal(tempTot)
                  setCustomOrderData({
                    locationId: data[0].producer.locationId,
                    orderId: data[0]._id,
                    allowableItems: data[0].allowableItems,
                    minQty: data[0].minQty,
                    customLI: data[0].member[0].customOrderData.lineItems,
                    memberId: data[0].member[0]._id,
                    processDate: data[0].processDate,
                    lockTicket: data[0].lockTicket,
                    inventoryUpdate: data[0].inventoryUpdate,
                    subTotal: subTotal,
                    discountAmount: discount,
                    discount: Number(data[0].club.discount.percentage),
                    initialLI: data[0].member[0].customOrderData.lineItems,
                    member: data[0].member
                  })
                }
              }


            }
            else {
              setLoading(false)
              setCustomActive(false)
            }
          }
          else {
            setLoading(false)
            setCustomActive(false)
          }
        })
        .catch(err => {
          setLoading(false)
          alert(`${err}`)
        })
    }
    return true
  }

  // Create our number formatter.
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const subtotal = (props) => {
    if (props) {
      let maxCallback = (acc, cur) => (acc + cur);
      return props.map(el => {
        return el.basePriceMoney.amount * Number(el.quantity)
      })
        .reduce(maxCallback) / 100

    } else {
      return 0
    }
  }


  const handleInputBundleChecked = (varId, info, mainItem) => { //updates the variation
    //first find if the the item is already in the order
    const index2 = customOrderData.customLI.findIndex((element) => element.uid === varId);
    if (index2 === -1) {
      var tempSearch = customOrderData.customLI
      tempSearch.push({
        variationName: info.itemVariationData.name,
        name: mainItem.name,
        brand: info.brand,
        key: info.itemVariationData.sku,
        basePriceMoney: info.itemVariationData.priceMoney,
        quantity: 1,
        total: info.itemVariationData.priceMoney.amount,
        uid: info.id,
        unitInfo: info.unitInfo,
        count: info.count
      })
      var discount = subtotal(tempSearch) * (customOrderData.discount * .01)
      var subTotal = subtotal(tempSearch) - discount
      var tempTot = 0
      tempSearch.map(item => {
        tempTot = tempTot + Number(item.quantity)
      })
      setOrderTotal(tempTot)
      setCustomOrderData(customOrderData => ({
        ...customOrderData,
        customLI: tempSearch,
        subTotal: subTotal,
        discountAmount: discount
      }))
    }
    else {
      var tempArr1 = customOrderData.customLI.map((selection => {
        if (selection.uid === varId) {
          if (customOrderData.inventoryUpdate === true) {
            if (Number(selection.count) >= Number(selection.quantity) + 1) {
              return { ...selection, quantity: Number(selection.quantity) + 1 }
            }
            else {
              setIsStatusVisible({ status: 200, visible: true, color: "red", text: "Max limit allowed reached for this item" })
              //timeout to hide after 3 seconds
              setTimeout(() => {
                setIsStatusVisible({ status: 200, visible: false, color: "", text: "" })
              }, 3000);
              return selection
            }
          }
          else {
            return { ...selection, quantity: Number(selection.quantity) + 1 }
          }
        }
        else return { ...selection }
      }))

      if (tempArr1[0]) {
        var discount = subtotal(tempArr1) * (customOrderData.discount * .01)
        var subTotal = subtotal(tempArr1) - discount
        var tempTot = 0
        tempArr1.map(item => {
          tempTot = tempTot + Number(item.quantity)
        })
        setOrderTotal(tempTot)
      }
      else {
        var discount = 0
        var subTotal = 0
        setOrderTotal(0)
      }
      setCustomOrderData(customOrderData => ({
        ...customOrderData,
        customLI: tempArr1,
        subTotal: subTotal,
        discountAmount: discount
      }))
    }
  }

  const handleInputRemoveItem = (varId) => {
    var tempArr1 = customOrderData.customLI.filter((selection => {
      return selection.uid !== varId
    }))

    if (tempArr1[0]) {
      var discount = subtotal(tempArr1) * (customOrderData.discount * .01)
      var subTotal = subtotal(tempArr1) - discount
      var tempTot = 0
      tempArr1.map(item => {
        tempTot = tempTot + Number(item.quantity)
      })
      setOrderTotal(tempTot)
    }
    else {
      var discount = 0
      var subTotal = 0
      setOrderTotal(0)
    }
    setCustomOrderData(customOrderData => ({
      ...customOrderData,
      customLI: tempArr1,
      subTotal: subTotal,
      discountAmount: discount
    }))
  }

  const handleInputSubtractItem = (varId) => {
    var tempArr1 = customOrderData.customLI.map((selection => {
      if (selection.uid === varId) {
        if (Number(selection.quantity) - 1 === 0) {
          return { ...selection, delete: true }
        }
        else {
          return { ...selection, quantity: Number(selection.quantity) - 1 }
        }

      }
      else return { ...selection }
    }))

    var tempArr2 = tempArr1.filter((selection => {
      return selection.delete !== true
    }))

    if (tempArr2[0]) {
      var discount = subtotal(tempArr2) * (customOrderData.discount * .01)
      var subTotal = subtotal(tempArr2) - discount
      var tempTot = 0
      tempArr2.map(item => {
        tempTot = tempTot + Number(item.quantity)
      })
      setOrderTotal(tempTot)
    }
    else {
      var discount = 0
      var subTotal = 0
      setOrderTotal(0)
    }
    setCustomOrderData(customOrderData => ({
      ...customOrderData,
      customLI: tempArr2,
      subTotal: subTotal,
      discountAmount: discount
    }))

  }

  const modalDisplay = () => {
    return (
      <>
        <div class={modalShow ? "modal  fade show opacity d-block" : "modal modal-fullscreen-md-down fade"} data-bs-backdrop="static" id="modalInfoSecondary" tabindex="-1" aria-labelledby="modalInfoSecondary" aria-hidden="true" style={{ zIndex: "3000" }}>
          <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <button className="btn btn-outline-secondary modal-btn" onClick={(e) => {
                  setCustomOrderData(customOrderData => ({
                    ...customOrderData,
                    customLI: customOrderData.initialLI
                  }));
                  showHideModal();
                }}><small>Close</small></button>
                {
                  orderTotal >= Number(customOrderData.minQty) ?
                    <button className="btn btn-outline-primary modal-btn" onClick={(e) => { updateCustomOrder(); setLoading(true) }}><small>Save</small></button>
                    :
                    null
                }
              </div>
              <div class="modal-body">
                {loading ?
                  <>
                    <div className="row w-100" style={{ position: "absolute", zIndex: "10000" }}>
                      <div className="col text-center">
                        <div className="spinner-border" style={{ width: "3rem", height: "3rem", marginTop: "12vw" }} role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  null
                }
                <div className="row">
                  <div className="col-12 col-md-7 order-2 order-md-1">
                    <div className="card sticky-top" style={{ top: "76px", minHeight: "100%" }}>
                      <div className="card-header d-flex justify-content-center"><h6 className=" my-2">ITEMS TO SELECT FROM</h6></div>
                      <div className="card-body mx-3">
                        <div>
                          <table class="table table-hover d-table" id="itemsTable2">
                            <thead className="w-100">
                              <tr>
                                <th style={{ width: "50%" }} scope="col"><small>Item</small></th>
                                <th style={{ width: "30%" }} scope="col"><small>Variation</small></th>
                                <th style={{ width: "20%" }} scope="col"><small>Price</small></th>
                              </tr>
                            </thead>
                            <tbody className="w-100">
                              {customOrderData.allowableItems.map((item, index) => {
                                if (item) {
                                  if (item.variation !== undefined) {
                                    return (
                                      <>
                                        {item.variation.map((c, i) => {
                                          if (i === 0) {
                                            if (customOrderData.inventoryUpdate === true && c.itemVariationData !== undefined && Number(c.count) > 0 || customOrderData.inventoryUpdate === false && c.itemVariationData !== undefined) {
                                              if (c.itemVariationData.priceMoney !== undefined) {
                                                if (c.itemVariationData.priceMoney.amount !== undefined) {
                                                  return (
                                                    <>
                                                      <tr style={{ borderWidth: "1px 0px 1px 0px", borderStyle: "solid", borderColor: "#ccc" }}>
                                                        <td className="text-truncate" style={{ width: "50%", maxWidth: "14vw" }} onClick={(e) => {
                                                          handleInputBundleChecked(c.id, c, item)
                                                        }}><small><strong>{item.name}</strong></small></td>
                                                        <td className="text-truncate" style={{ width: "50%", maxWidth: "14vw" }} onClick={(e) => {
                                                          handleInputBundleChecked(c.id, c, item)
                                                        }}><small>{c.itemVariationData.name}</small></td>
                                                        <td style={{ width: "20%" }} onClick={(e) => {
                                                          handleInputBundleChecked(c.id, c, item)
                                                        }}><small>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(c.itemVariationData.priceMoney.amount / 100)}</small></td>
                                                      </tr>
                                                    </>
                                                  )
                                                }
                                              }
                                            }
                                          }
                                          else {
                                            if (customOrderData.inventoryUpdate === true && c.itemVariationData !== undefined && Number(c.count) > 0 || customOrderData.inventoryUpdate === false && c.itemVariationData !== undefined) {
                                              if (c.itemVariationData.priceMoney !== undefined) {
                                                if (c.itemVariationData.priceMoney.amount !== undefined) {
                                                  return (
                                                    <>
                                                      <tr style={{ borderWidth: "1px 0px 1px 0px", borderStyle: "solid", borderColor: "#ccc" }}>
                                                        <td style={{ width: "30%", paddingLeft: "30px" }} onClick={(e) => {
                                                          handleInputBundleChecked(c.id, c, item)
                                                        }}></td>
                                                        <td className="text-truncate" style={{ width: "50%" }} onClick={(e) => {
                                                          handleInputBundleChecked(c.id, c, item)
                                                        }}><small>{c.itemVariationData.name}</small></td>
                                                        <td style={{ width: "20%" }} onClick={(e) => {
                                                          handleInputBundleChecked(c.id, c, item)
                                                        }}><small>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(c.itemVariationData.priceMoney.amount / 100)}</small></td>
                                                      </tr>
                                                    </>
                                                  )
                                                }
                                                else {
                                                }
                                              }
                                              else {
                                              }
                                            }
                                            else {
                                            }
                                          }
                                        })
                                        }
                                      </>
                                    )
                                  }
                                }
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col order-1 order-md-2 pb-4">
                    <div className="card sticky-top" style={{ top: "76px", minHeight: "100%" }}>
                      <div className="card-header d-flex justify-content-center"><h6 className=" my-2">{"ORDER SUMMARY - " + orderTotal + " ITEMS"}</h6></div>
                      <div className="card-body mx-3">
                        {
                          orderTotal >= Number(customOrderData.minQty) ?
                            <>
                            </>
                            :
                            <>
                              <div class="alert alert-danger" role="alert">
                                <small> A minimum order of {Number(customOrderData.minQty)} items is required</small>
                              </div>
                            </>
                        }
                        <p><small>Estimated Process Date: {new Date(customOrderData.processDate).toLocaleDateString("en-US")}</small></p>
                        {customOrderData.customLI.map((i, index) => {
                          if (i.name.slice(0, 8) !== "Shipping")
                            return (
                              <>
                                <div className="mb-1">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                      {i.baseItem === true && customOrderData.lockTicket === true ?
                                        null
                                        :
                                        <>
                                          <button type="button" class="btn btn-outline-secondary btn-sm p-0 border-0 me-1" onClick={(e) => { handleInputRemoveItem(i.uid) }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                            </svg>
                                            <span class="visually-hidden">Delete</span>
                                          </button>
                                        </>
                                      }
                                      <p className="card-title  mb-0">
                                        <strong>{i.name} {i.vintage}</strong>
                                      </p>
                                    </div>
                                    <div style={{ minWidth: "30%" }} align="right">
                                      <p className="card-text mb-0">{Number(i.quantity) + " X " + formatter.format((i.basePriceMoney.amount / 100))}</p>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <p className="card-text ms-4">
                                      <small className="text-muted">{i.variationName}</small>
                                    </p>
                                    <div style={{ minWidth: "30%" }} align="right">
                                      <div class="btn-group">
                                        {i.baseItem === true && customOrderData.lockTicket === true && Number(i.baseQuantity) >= Number(i.quantity) ?
                                          null
                                          :
                                          <>
                                            <button type="button" class="btn btn-outline-secondary btn-sm p-0 border-0"
                                              onClick={(e) => { handleInputSubtractItem(i.uid) }}>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                                              </svg>
                                              <span class="visually-hidden">Subtract</span>
                                            </button>
                                          </>
                                        }
                                        <button type="button" class="btn btn-outline-secondary btn-sm border-0" >

                                          <span class="visually-hidden">Button</span>
                                        </button>
                                        <button type="button" class="btn btn-outline-secondary btn-sm p-0 border-0" onClick={(e) => { handleInputBundleChecked(i.uid, null) }}>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"></path>
                                          </svg>
                                          <span class="visually-hidden">Add</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                        })}
                        <div className="d-flex justify-content-between">
                          <p className="card-text mb-1">
                            Club Discount ({customOrderData.discount + '%'})
                          </p>
                          <p className="card-text mb-1" style={{ minWidth: "30%" }} align="right">
                            -{formatter.format(customOrderData.discountAmount)}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="card-text mb-1">
                            Shipping
                          </p>
                          <p className="card-text mb-1" style={{ minWidth: "30%" }} align="right">
                            <small>Calculated at Processing</small>
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="card-text mb-1">
                            Taxes
                          </p>
                          <p className="card-text mb-1" style={{ minWidth: "30%" }} align="right">
                            <small>Calculated at Processing</small>
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="card-text mb-1">
                            Subtotal
                          </p>
                          <p className="card-text mb-1" style={{ minWidth: "30%" }} align="right">
                            {formatter.format(customOrderData.subTotal)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={isStatusVisible.visible ? "modalStatus is-visible" : "modalStatus"} style={{ backgroundColor: isStatusVisible.color }}><span>{isStatusVisible.text}</span></div>

            </div>
          </div>
        </div>
      </>
    )
  }


  return (
    <>
      {modalDisplay()}
      <header className="row no-gutters page-header">
        <div className="col-12">
          <nav class="navbar bg-none">
            <div class="container-fluid">
              <a class="navbar-brand">VinSipp</a>
              <form class="d-flex" role="search">
                <button class="btn btn-outline-success" onClick={handleLogout}>Logout</button>
              </form>
            </div>
          </nav>


        </div>
      </header>

      <div className="row justify-content-center" style={{ "marginTop": "90px" }}>
        <div className="col-12 justify-self-center" align="center" style={{ "height": "calc(100vh - 90px)" }}>
          <div className="row" style={{ "height": "100%" }}>
            {userInfoRet()}
            <div className="col-12 col-md-9 mt-md-2 ps-4 rightNav" style={{ width: "84%" }}>
              {userDataFromSelection()}
            </div>
          </div>
        </div>
      </div>

    </>
  );
}