import { AnonymousCredential, UserPasswordCredential, UserPasswordAuthProviderClient } from "mongodb-stitch-browser-sdk";
import { app } from "./app.js";

export function addAuthenticationListener(listener) {
    app.auth.addAuthListener(listener);
}
export function removeAuthenticationListener(listener) {
    app.auth.removeAuthListener(listener);
}
export function loginAnonymous() {
  // Allow users to log in anonymously
  const credential = new AnonymousCredential();
  return app.auth.loginWithCredential(credential);
}
export function loginEmailPassword(username, password, firstLogin) {
    //Allows user to log in with email and password
    const credential = new UserPasswordCredential(username, password)
    return app.auth.loginWithCredential(credential)
    // Returns a promise that resolves to the authenticated user
    .then(authedUser => console.log('successfully logged in'))
    .catch(err => {
        console.error(`login failed with error: ${err.message}`)
        return err })
}
export function hasLoggedInUser() {
  // Check if there is currently a logged in user
  return app.auth.isLoggedIn;
}

export function getCurrentUser() {
  // Return the user object of the currently logged in user
  return app.auth.isLoggedIn ? app.auth.user : null;
}

export function logoutCurrentUser() {
  // Logout the currently logged in user
  const user = getCurrentUser();
  return app.auth.logoutUserWithId(user.id);
}

export function resetPassword(email) {
  const emailPassClient = app.auth
  .getProviderClient(UserPasswordAuthProviderClient.factory);

return emailPassClient.sendResetPasswordEmail(email).then(() => {
  console.log("Successfully sent password reset email!");
    return "Successfully sent password reset email!"
}).catch(err => {
  console.log("Error sending password reset email:", err)
    return err
});
}
export function newPasswordReset(token, tokenId, newPassword) {
        // Confirm the user's email/password account
const emailPassClient = app.auth
  .getProviderClient(UserPasswordAuthProviderClient.factory);

 return emailPassClient.resetPassword(token, tokenId, newPassword).then(() => {
  console.log("Successfully reset password!")
    return "Successfully reset your password!"
}).catch(err => {
  console.log("Error resetting password:", err)
    return err });
}

