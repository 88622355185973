import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { MdClose } from 'react-icons/md';
import { IconContext } from "react-icons";
import './index.css';
import { useStitchAuth } from "../../App/StitchAuth";
import { app } from "../../MongoStitch/app";
import { BSON, UserPasswordAuthProviderClient } from 'mongodb-stitch-browser-sdk';


export default function ConsumerSignUp(props) {
  const [sendStatus, setSendStatus] = useState(0)
  const [registerStatus, setRegisterStatus] = useState(false)
  const { register, handleSubmit, watch, errors } = useForm();
  const [createAcctError, setCreateAcctError] = useState('');
  const [dataField, setDataField] = useState({});


  const resendConfirm = () => {

    const emailPasswordClient = app.auth
      .getProviderClient(UserPasswordAuthProviderClient.factory);
    emailPasswordClient.resendConfirmationEmail(dataField.email)
      .then(result => {
        setDataField({ ...dataField, message: "Email Resent" })
      }
      )
      .catch(err => {
        setCreateAcctError(err.message)
      })
  }

  const onSubmit = (data, e) => {
    setSendStatus(1)
    setDataField(data)
    if (data !== undefined) {
      switch (data.password1) {
        case data.password2: {
          e.preventDefault();
          const emailPasswordClient = app.auth
            .getProviderClient(UserPasswordAuthProviderClient.factory);
          emailPasswordClient.registerWithEmail(data.email, data.password1)
            .then(result => {
              if (result === undefined) {
                setRegisterStatus(true)
                setSendStatus(0)
                document.getElementById("contact").reset()
                setCreateAcctError('')
              }
            })
            .catch(err => {
              setSendStatus(0);
              setCreateAcctError(err.message)
            })
        }
          break;
        default: { setSendStatus(0); setCreateAcctError("Passwords Dont Match") }
      }
    }
  }


  function handleChange() {
    // Here, we invoke the callback with the new value
    props.onChange();
  }

  const submitButton = () => {
    if (sendStatus === 1) {
      return <button className="vinButton loading" type="submit">Submitting</button>
    }
    else {
      return (
        <button className="vinButton" type="submit">Sign Up</button>
      )
    }
  }

  const registrationForm = () => {
    return (
      <>
        <section className={props.modal ? "modal  fade show opacity d-block" : "modal modal-fullscreen-md-down fade"} style={{ "padding": "0", zIndex:"100000" }}>
          <div className="modal__content">
            <div className="row justify-content-center no-gutters">
              <span onClick={handleChange} style={{ "cursor": "pointer", "width": "100%", "textAlign": "right", "marginRight": "3em", "marginBottom": "-1em" }}>
                <IconContext.Provider value={{ size: "3em" }}>
                  <MdClose />
                </IconContext.Provider>
              </span>
              <div className="col" style={{ "padding": "0 4rem" }}>
                <h1 align="center">Let's create your Account</h1>
                <p align="center">Signing up for Vinsipp is fast and free - no commitments or contracts</p>
                <div align="center" style={{ "backgroundColor": "red", "color": "#fff", "fontWeight": "600", "textTransform": "capitalize" }}>{createAcctError}</div>
                <form id="contact" onSubmit={handleSubmit(onSubmit)} className="specialForm">
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>first name</label>
                      <input name="firstName" ref={register({ required: true })} className="form-control" ></input>
                      {errors.firstName && <div className="error__message">required</div>}
                    </div>
                    <div className="form-group col-md-6">
                      <label>last name</label>
                      <input name="lastName" ref={register({ required: true })} className="form-control" ></input>
                      {errors.lastName && <div className="error__message">required</div>}

                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-12">
                      <label>Enter your email</label>
                      <input name="email" ref={register({ required: true })} className="form-control" id="email" type="email"
                        autoCapitalize="off" autoComplete="off" autoCorrect="off" autoFocus="autofocus">
                      </input>
                      {errors.email && <div className="error__message">required</div>}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>create a password</label>
                      <input name="password1" ref={register({ required: true })} className="form-control" id="password1" type="password" autoCapitalize="off" autoComplete="off" autoCorrect="off" autoFocus="autofocus" spellCheck="false"></input>
                      {errors.password1 && <div className="error__message">required</div>}
                    </div>
                    <div className="form-group col-md-6">
                      <label>confirm your password</label>
                      <input name="password2" ref={register({ required: true })} className="form-control" id="password2" type="password"></input>
                      {errors.password2 && <div className="error__message">required</div>}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-12">
                      <label>state</label>
                      <input name="phone" ref={register({ required: true })} className="form-control" type="text"></input>
                      {errors.phone && <div className="error__message">required</div>}
                    </div>
                  </div>

                  <div className="form-field" style={{ "margin": "1em 0 1em .5em" }}><label><input type="checkbox" name="acceptTerms" ref={register({ required: true })}></input>{errors.acceptTerms && <div className="error__message">required</div>}<span class="fauxBox"></span>I accept <a href="https://www.vincipia.com/terms-of-service" target="_blank">Vincipia terms</a>, <a href="https://www.vincipia.com/e-sign-consent" target="_blank">e-sign consent</a>, and <a href="https://www.vincipia.com/privacy-notice" target="_blank">privacy notice</a></label></div>
                  {submitButton()}
                </form>
              </div>
            </div>
            <div align="center">
              <small >Powered by Vincipia</small>
            </div>
          </div>
        </section>
      </>
    );
  }

  const registerSuccess = () => {
    return (
      <>
        <section className={props.modal ? "modal  fade show opacity d-block" : "modal modal-fullscreen-md-down fade"} style={{ "padding": "0" }}>
          <div className="modal__content">
            <div className="row justify-content-center no-gutters">
              <span onClick={handleChange} style={{ "cursor": "pointer", "width": "100%", "textAlign": "right", "marginRight": "3em", "marginBottom": "-1em" }}>
                <IconContext.Provider value={{ size: "3em" }}>
                  <MdClose />
                </IconContext.Provider>
              </span>
              <div className="col" style={{ "padding": "0 4rem" }}>
                <h1 align="center">success!</h1>
                <p align="center">You're almost there!  We sent an email to the address you used to signup.</p>
                <p align="center">Just click on the link in that email to complete your signup.</p>
                <p align="center">If you dont see it, you may need to check your spam folder.</p>
                <p align="center">Still cant find the email?</p>
                <button onClick={resendConfirm} align="center">Resend Email</button>
                {dataField.message}
                <button onClick={handleChange}>Login</button>
                <div align="center"><small>Powered by Vincipia</small></div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }

  const displaySwitch = () => {
    switch (registerStatus) {
      case true: return registerSuccess();
        break;
      default: return registrationForm();
    }
  }

  return displaySwitch()
}