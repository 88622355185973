import { RemoteMongoClient } from "mongodb-stitch-browser-sdk";
import { app } from "./app";

const mongoClient = app.getServiceClient(
  RemoteMongoClient.factory,
  "mongodb-atlas"
);


const clients = mongoClient.db("VinApp_v1-0-0-Beta").collection("clients");
const users = mongoClient.db("VinSipp_Users").collection("club_members");
const orders = mongoClient.db("VinApp_v1-0-0-Beta").collection("orders");
export { clients,users, orders };
