import {
    clients,
    users,
    orders
} from "../MongoStitch";
import {
    app
} from "../MongoStitch/app";
import {
    BSON
} from 'mongodb-stitch-browser-sdk';

const getInventoryStatus = async (props) => {
    var variationArr = []
    if (props.items[0]) {
        props.items.map(item => {
            if (item.variation) {
                item.variation.map(variation => {
                    variationArr.push(variation.id)
                })
            }
        })
    }

    var newData = app.callFunction("SQUARE_Products", [props.pid, 'batchRetrieveInv', variationArr])
        .then(result => {
            if (result.status === 200) {
                return { status: 200, data: result.data.counts }
            }
            else {
                return { status: 400 }
            }
        })
        .catch(err => {
            console.log(err)
            return { status: 400 }
        })

    return newData
}

const updateInventoryStatus = async (props) => {

    var newData = app.callFunction("SQUARE_Products", [props.pid, 'batchUpdateInv', { idempotencyKey: new BSON.ObjectId(), catalogObjectIds: props.catalogObjectIds }])
        .then(result => {
            if (result.status === 200) {
                return { status: 200 }
            }
            else {
                return { status: 400 }
            }
        })
        .catch(err => {
            console.log(err)
            return { status: 400 }
        })

    return newData
}

const updateOrder = async (props) => {
    var tempData = props
    tempData.member[0].customOrderData.lineItems = props.customLI

    var newData = await orders.updateOne({
        _id: props.orderId,
        "members._id": new BSON.ObjectId(props.memberId)
    }, {
        $set: {
            'members.$': {
                ...tempData.member[0],
                customOrderData: tempData.member[0].customOrderData
            }
        }
    })
        .then(result => {
            const { matchedCount, modifiedCount } = result;
            if (matchedCount && modifiedCount) {
                //UPDATE THE SQUARE INVENTORY!!!! BUT OVLY IF INVENTORY CHANGE IS SET AS TRUE
                return { status: 200, data: {} }
            }
            else {
                return { status: 400 }
            }
        })
        .catch(err => {
            console.log(err)
            return { status: 400 }
        })
    return newData
}

const calcDifferenceInv = (props) => {
    //props.initialLI vs. props.customLI
    //check to see what isnt there anymore
    var removedArr = []
    var updatedArr = []
    var addedArr = []
    //find existing item inv difference
    props.initialLI.map(init => { //the initial line items
        //map through the initial line items
        props.customLI.map(current => { //the current line items
            if (current.uid === init.uid) {
                if (current.quantity !== init.quantity) {
                    var difference = Number(current.quantity) - Number(init.quantity)
                    switch (Math.sign(difference)) {
                        case -1: {
                            updatedArr.push({
                                type: 'ADJUSTMENT',
                                adjustment: {
                                    fromState: 'NONE',
                                    toState: 'IN_STOCK',
                                    locationId: props.locationId,
                                    catalogObjectId: current.uid,
                                    occurredAt: new Date(),
                                    quantity: Math.abs(difference).toString()
                                }
                            })
                        }
                            break;
                        case 1: {
                            updatedArr.push({
                                type: 'ADJUSTMENT',
                                adjustment: {
                                    fromState: 'IN_STOCK',
                                    toState: 'SOLD',
                                    locationId: props.locationId,
                                    catalogObjectId: current.uid,
                                    occurredAt: new Date(),
                                    quantity: difference.toString()
                                }
                            })
                            break;
                        }
                    }

                }
            }
        })
    })

    var customUIDs = props.customLI.map(cust => {
        return cust.uid
    })
    var initUIDs = props.initialLI.map(cust => {
        return cust.uid
    })

    var difference = customUIDs.filter(x => !initUIDs.includes(x));
    removedArr = initUIDs.filter(x => !customUIDs.includes(x));

    //find the added items
    difference.map(d => {
        if (initUIDs.indexOf(d) === -1) {
            addedArr.push(d)
        }
    })

    var removedFinal = removedArr.map(uid => {
        var data = props.initialLI.filter(init => init.uid === uid)
        return {
            type: 'ADJUSTMENT',
            adjustment: {
                fromState: 'NONE',
                toState: 'IN_STOCK',
                locationId: props.locationId,
                catalogObjectId: data[0].uid,
                occurredAt: new Date(),
                quantity: data[0].quantity.toString()
            }
        }


    })

    var addedFinal = addedArr.map(uid => {
        var data = props.customLI.filter(init => init.uid === uid)
        return {
            type: 'ADJUSTMENT',
            adjustment: {
                fromState: 'IN_STOCK',
                toState: 'SOLD',
                locationId: props.locationId,
                occurredAt: new Date(),
                catalogObjectId: data[0].uid,
                quantity: data[0].quantity.toString()
            }
        }
    })

    return updatedArr.concat(removedFinal, addedFinal);
}

export {
    updateOrder,
    getInventoryStatus,
    calcDifferenceInv,
    updateInventoryStatus
}