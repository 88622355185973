import React, { useState, useEffect } from "react";
import './App.css';
import { StitchAuthProvider, useStitchAuth } from "./StitchAuth";
import ClubSignup from '../ClubSignup';
import Login from '../Login';
import DataSwitch from '../DataSwitch';
import 'bootstrap/dist/css/bootstrap.css'

export default function App() {
  const [queryString, setQueryString] = useState(window.location.search);
  const [urlParams, setUrlParams] = useState(new URLSearchParams(queryString));
  const [uid, setUid] = useState(urlParams.get('uid'));

  useEffect(() => {

  }, []);

  if (uid) { //if there is a UID Load the signup page
    return (
      <StitchAuthProvider>
        <ClubSignup />
      </StitchAuthProvider>
    )
  } else { // if there isnt show the login and follow the tree structure
    return (
      <StitchAuthProvider>
          <AppUI />
      </StitchAuthProvider>
    );
  }
}

function AppUI() {
  const { isLoggedIn } = useStitchAuth();
  return (
    <div> {isLoggedIn ? <DataSwitch /> : <Login />}
    </div>
  );
}