import React, {useState,useEffect} from "react";
import {useStitchAuth} from "../App/StitchAuth";
import { app } from "../MongoStitch/app";
import BillingInfo from '../SignUp'
import { BSON } from 'mongodb-stitch-browser-sdk';
import { clients } from "../MongoStitch";

export default function ClubSignup(props) {
  const [queryString, setQueryString] = useState(window.location.search);
  const [urlParams, setUrlParams] = useState(new URLSearchParams(queryString));
  const [uid, setUid] = useState(urlParams.get('uid'));
  const [clubData, setClubData] = useState({club:{}});
  const [customerId, setCustomerId] = useState("");
  const {
    actions
  } = useStitchAuth();
  const [inputText, setInputText] = useState({});
  
    useEffect(() => {
        if (uid !== null){
            findTheClub()
        }
    }, [uid]);

    const findTheClub = async () => {
      const provider = 'anonymous';
      actions.handleLogin({
          provider
        }).then(function (result) {
          const agg = [{
            $match: {
              "clubs._id": new BSON.ObjectId(uid)
            }
          }, {
            $project: {
              _id: 0,
              'activeStates': {
                '$filter': {
                  'input': '$stateCompliance',
                  'as': 'state',
                  'cond': {
                    '$eq': [
                      '$$state.offSite', true
                    ]
                  }
                }
              },
              'club': {
                '$filter': {
                  'input': '$clubs',
                  'as': 'club',
                  'cond': {
                    '$eq': [
                      '$$club._id', new BSON.ObjectId(uid)
                    ]
                  }
                }
              },
              producerName: 1,
              stateCompliance: 1
            }
          }, {
            $unwind: {
              path: '$club',
            }
          }]
          clients.aggregate(agg).asArray()
            .then(data => {
              setClubData(data[0])
            })
            .catch(err => console.error(`Failed to find and update document: ${err}`))
            .catch(err => alert(`${err}`))
        })
        .catch(err => alert(`${err}`))
    }
    
  return (
  <>
    <div className="container-fluid" style={{"height": "100vh"}}>
      <div className="row justify-content-center">
        <div className="col-12 justify-self-center" align="center">
          <h1>{clubData.producerName}</h1>
          <h2>{clubData.club.name}</h2>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-8 justify-self-center" align="center">
          <p>{clubData.club.description}</p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-7">
          <BillingInfo uid={uid} states={clubData.activeStates} allStates={clubData.stateCompliance} prodName={clubData.producerName} />
        </div>
      </div>
    </div>
  </>
  );
}