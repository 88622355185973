import React, {
  useState,
  useEffect
} from "react";
import {
  useStitchAuth
} from "../App/StitchAuth";
import { app } from "../MongoStitch/app";
import PaymentPage  from "../Square/paymentPage"
import Receipt  from "../Receipt"

export default function BillingInfo(props) {
    const [queryString, setQueryString] = useState(window.location.search);
    const [urlParams, setUrlParams] = useState(new URLSearchParams(queryString));
    const [uid, setUid] = useState(urlParams.get('uid'));
    const [inputText, setInputText] = useState({});
    const [inputTextShipping, setInputTextShipping] = useState({email:""});
    const [customerId, setCustomerId] = useState();
    const [deliveryState, setDeliveryState] = useState(false)
    const [billingState, setBillingState] = useState(false)
    const [shippingState, setShippingState] = useState(false)
    const [cardSuccess, setCardSuccess] = useState(false)
    const [error, setError] = useState({})
    const [spinner, setSpinner] = useState(false)
    const [createMemberState, setCreateMemberState] = useState(false)
    const {
    actions
  } = useStitchAuth();
    
    const handleInput = e => {
      e.persist();
      const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      switch (e.target.name) {
        default:
          setInputText(inputText => ({
            ...inputText,
            [e.target.name]: value
          }));
      }
    }
    
    const handleInputShipping = e => {
      e.persist();
      const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      switch (e.target.name) {
        default:
          setInputTextShipping(inputTextShipping => ({
            ...inputTextShipping,
            [e.target.name]: value
          }));
      }
    }
    
    const handleInputShippingSameAsBilling = e => {
      e.persist();
      const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      switch (value) {
        case true:
          setInputTextShipping({
            ...inputText
          })
          break;
        case false:
          {
            for (const [key, value] of Object.entries(inputTextShipping)) {
              setInputTextShipping(inputTextShipping => ({
                ...inputTextShipping,
                [key]: ""
              }));
            }

          }
          break;
        default:
          return
      }
    }
    
    const cancelCustomer = () => { //!!!!!!! Cancel button which deletes the customer from Square !!!!!!
      setSpinner(!spinner);
      if (customerId !== undefined) {
        app.callFunction("SQUARE_Customers", ["deleteCustomer", {
            clubId: props.uid,
            sq_refId: customerId
          }]).then(result => {
            switch (result.status) {
              case 400:
                alert('Uh Oh! Something seems to have gone wrong');
                break;
              case 410:
                alert('ERROR: ' + result.text); //Vincipia error based on mongo return
                break;
              case 200:
                setDeliveryState(!deliveryState);
                setInputText({});
                setCardSuccess(false);
                setBillingState(false);
                setCustomerId();
                setSpinner(false);
                break;
              default:
                alert('Uh Oh! Something seems to have gone wrong.  Please try again.')
            }
          })
          .catch(err => alert('Uh Oh! Something seems to have gone wrong connecting to the server.  Please try again.'))
      } else {
        setDeliveryState(!deliveryState);
        setInputText({});
        setCardSuccess(false);
        setBillingState(false);
        setCustomerId();
        setSpinner(false);
      }
    }
    
    const validation = () => {
      setError({})
      let etmpObj = inputText
      let i = 0

      for (const property in etmpObj) {
        if (etmpObj.given_name === null || etmpObj.given_name === undefined || etmpObj.given_name.trim() === '') {
          setError(error => ({
            ...error,
            given_name: "Field Required"
          }))
          i++
        }
        if (etmpObj.family_name === null || etmpObj.family_name === undefined || etmpObj.family_name.trim() === '') {
          setError(error => ({
            ...error,
            family_name: "Field Required"
          }))
          i++
        }
        if (etmpObj.email === null || etmpObj.email === undefined || etmpObj.email.trim() === '') {
          setError(error => ({
            ...error,
            email: "Field Required"
          }))
          i++
        }
        if (etmpObj.address_line_1 === null || etmpObj.address_line_1 === undefined || etmpObj.address_line_1.trim() === '') {
          setError(error => ({
            ...error,
            address_line_1: "Field Required"
          }))
          i++
        }
        if (etmpObj.administrative_district_level_1 === null || etmpObj.administrative_district_level_1 === undefined || etmpObj.administrative_district_level_1.trim() === '') {
          setError(error => ({
            ...error,
            administrative_district_level_1: "Field Required"
          }))
          i++
        }
        if (etmpObj.locality === null || etmpObj.locality === undefined || etmpObj.locality.trim() === '') {
          setError(error => ({
            ...error,
            locality: "Field Required"
          }))
          i++
        }
        if (etmpObj.postal_code === null || etmpObj.postal_code === undefined || etmpObj.postal_code.trim() === '') {
          setError(error => ({
            ...error,
            postal_code: "Field Required"
          }))
          i++
        }
        if (etmpObj.phone_number === null || etmpObj.phone_number === undefined || etmpObj.phone_number.trim() === '') {
          setError(error => ({
            ...error,
            phone_number: "Field Required"
          }))
          i++
        }
      }
      if (i > 0) {
        return null
      } else {
        submitForm()
      }
    };

    const validation2 = () => {
      setError({})
      let etmpObjShipAddress = inputTextShipping
      let i = 0

      for (const property in etmpObjShipAddress) {

        if (etmpObjShipAddress.given_name === null || etmpObjShipAddress.given_name === undefined || etmpObjShipAddress.given_name.trim() === '') {
          setError(error => ({
            ...error,
            given_nameShipAddress: "Field Required"
          }))
          i++
        }
        if (etmpObjShipAddress.family_name === null || etmpObjShipAddress.family_name === undefined || etmpObjShipAddress.family_name.trim() === '') {
          setError(error => ({
            ...error,
            family_nameShipAddress: "Field Required"
          }))
          i++
        }
        if (etmpObjShipAddress.email === null || etmpObjShipAddress.email === undefined || etmpObjShipAddress.email.trim() === '') {
          setError(error => ({
            ...error,
            emailShipAddress: "Field Required"
          }))
          i++
        }
        if (etmpObjShipAddress.address_line_1 === null || etmpObjShipAddress.address_line_1 === undefined || etmpObjShipAddress.address_line_1.trim() === '') {
          setError(error => ({
            ...error,
            address_line_1ShipAddress: "Field Required"
          }))
          i++
        }
        if (etmpObjShipAddress.administrative_district_level_1 === null || etmpObjShipAddress.administrative_district_level_1 === undefined || etmpObjShipAddress.administrative_district_level_1.trim() === '') {
          setError(error => ({
            ...error,
            administrative_district_level_1ShipAddress: "Field Required"
          }))
          i++
        }
        if (etmpObjShipAddress.locality === null || etmpObjShipAddress.locality === undefined || etmpObjShipAddress.locality.trim() === '') {
          setError(error => ({
            ...error,
            localityShipAddress: "Field Required"
          }))
          i++
        }
        if (etmpObjShipAddress.postal_code === null || etmpObjShipAddress.postal_code === undefined || etmpObjShipAddress.postal_code.trim() === '') {
          setError(error => ({
            ...error,
            postal_codeShipAddress: "Field Required"
          }))
          i++
        }
        if (etmpObjShipAddress.phone_number === null || etmpObjShipAddress.phone_number === undefined || etmpObjShipAddress.phone_number.trim() === '') {
          setError(error => ({
            ...error,
            phone_numberShipAddress: "Field Required"
          }))
          i++
        }
      }
      if (i > 0) {
        return null
      } else {
        setShippingState(true)
      }
    };
    
    const handleCard = (newValue) =>{
        setCardSuccess(newValue)
    }
    
    const deliveryOptions = () => {
        if (props.states !== undefined) {
                    switch (deliveryState) {
                        case true: 
                            return ( 
                                <><span className="js-state-select-label wsite-checkout-form__label">Delivery Option: {inputText.pickupShip}</span></>
                            )
                        break;
                        default:
                            return (
                            <>
                            <div className="wsite-checkout-form__row ">
                                <label className="wsite-checkout-form__field wsite-checkout-form__field--state"> <span className="js-state-select-label wsite-checkout-form__label">How would you like to recieve your membership?</span>
                                    <div className="wsite-checkout-form__select-wrap">
                                        <select className="js-state-selection wsite-checkout-form__select" name="pickupShip"  value={inputText.pickupShip} onChange={handleInput}>
                                            <option value="Pickup">Pickup</option>
                                            <option value="Ship">Ship</option>
                                        </select>
                                    </div>
                                    <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="pickupShip"></div>
                                </label>
                            </div>
                            </>
                            )
                    }
        }
    }
    const states = () => {
        if (props.states !== undefined) {
            switch (inputText.pickupShip) {
              case null:
                return
                break;
              case undefined:
                return
                break;
              case "":
                return
                break;
              case "Pickup":
                    switch (deliveryState) {
                        case true: return (
                            <>
                                           <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0">
                          <span className="wsite-button-inner">
                              <div className={`wsite-next-button-label ${spinner ? "loading" : ""}`} onClick={()=>{cancelCustomer()}}>Change Selections</div> 
                          </span>
                        </button>
            <div className="js-form-invalid-text wsite-checkout-form__invalid-text" style={{"margin-bottom":"40px"}}><small>Changing the delivery option will reset the form</small></div>
                    </>
                    )
                        break;
                        default:
                return (
                    <section className="wsite-checkout-payment__section wsite-checkout-proceed js-shipping-information-actions">
                      <fieldset className="wsite-checkout-form__fieldset">
                        <div className="wsite-checkout-spinner wsite-checkout-spinner--small is-hidden js-checkout-spinner"></div>
                        <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0" style={{"margin-bottom":"40px"}}>
                          <span className="wsite-button-inner">
                              <div className={`wsite-next-button-label ${spinner ? "loading" : ""}`} onClick={()=>{setDeliveryState(!deliveryState)}}>Next</div> 
                          </span>
                        </button>
                      </fieldset>
                    </section>
                    )
            }
                break;
              default:
                    switch (deliveryState) {
                        case true: return ( <><span className="js-state-select-label wsite-checkout-form__label">State: {inputText.administrative_district_level_1}</span>
                                           <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0">
                          <span className="wsite-button-inner">
                            <div className="wsite-next-button-label" onClick={()=> {setDeliveryState(!deliveryState); setInputText({}); setCardSuccess(false); setBillingState(false); setCustomerId()}}>Change Selections</div>
                          </span>
                        </button>
          <div className="js-form-invalid-text wsite-checkout-form__invalid-text" style={{"margin-bottom":"40px"}}><small>Changing the delivery or state selections will reset the form</small></div>
                                           </>)
                        break;
                        default:
                    
                return (
                <>
                  <div className="wsite-checkout-form__row ">
                    <label className="wsite-checkout-form__field wsite-checkout-form__field--state"> <span className="js-state-select-label wsite-checkout-form__label">Please Select Your State <small>(Only states we ship to will be visable)</small></span>
                      <div className="wsite-checkout-form__select-wrap">
                        <select className="js-state-selection wsite-checkout-form__select" name="administrative_district_level_1" value={inputText.administrative_district_level_1} onChange={handleInput}>
                          <option></option>
                          {props.states.map((data, index) => (
                          <option value={data.name}>{data.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="administrative_district_level_1">{error.administrative_district_level_1}</div>
                    </label>
                  </div>
                    <section className="wsite-checkout-payment__section wsite-checkout-proceed js-shipping-information-actions">
                      <fieldset className="wsite-checkout-form__fieldset">
                        <div className="wsite-checkout-spinner wsite-checkout-spinner--small is-hidden js-checkout-spinner"></div>
                        <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0" style={{"margin-bottom":"40px"}}>
                          <span className="wsite-button-inner">
                            <div className="wsite-next-button-label" onClick={()=> {
                                                                              switch (inputText.administrative_district_level_1){
                                                                              case null: setError({administrative_district_level_1:"Required"})
                                                                              break;
                                                                              case undefined: setError({administrative_district_level_1:"Required"})
                                                                              break;
                                                                              case "": setError({administrative_district_level_1:"Required"})
                                                                              break;
                                                                              default:setDeliveryState(!deliveryState); setError({})
                                                                             }
                                                                              }}>Next</div>
                          </span>
                        </button>
                      </fieldset>
                    </section>
                </>
                )
            }
            }
            
        }
    }
    
    

    const allStates = () => {
        if (props.allStates !== undefined) {
            switch (inputText.pickupShip) {
                case "Ship":
                return(
                <>
                  <select className="js-state-selection wsite-checkout-form__select" name="administrative_district_level_1" value={inputText.administrative_district_level_1} onChange={handleInput}>
                    <option>{inputText.administrative_district_level_1}</option>
                  </select>
                </>
                )
                break;
                case "Pickup":
                return(
                    <>
                      <select className="js-state-selection wsite-checkout-form__select" name="administrative_district_level_1" value={inputText.administrative_district_level_1} onChange={handleInput}>
                        <option></option>
                        {props.allStates.map((data, index) => (
                        <option value={data.name}>{data.name}</option>
                        ))}
                      </select>
                    </>
                    )
                break;
                default: 
                return
        }
    }
}

const billingInfo = () => {
        if (props.states !== undefined) {
            switch (deliveryState){
                case null: return
                break;
                case undefined: return
                break;
                case "": return
                break;
                case true:
                    switch (billingState) {
                      case false:
                        return billingNoId()
                        break;
                      case true:
                        return (
                           <>
                             {billingWithId()}
                             <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0" style={{"marginBottom":"40px"}} >
                               <span className="wsite-button-inner">
                                 <div className="wsite-next-button-label" onClick={()=>setBillingState(!billingState)}>Update</div>
                               </span>
                             </button>
                           </>
                        )
                    break;
                }
                break;
                default: return (
                <div class="wsite-checkout-payment__step" style={{"display": "block"}}><header class="wsite-checkout-payment__header wsite-checkout-payment__container"><span class="js-payment-header-title wsite-checkout-payment__header-title">
	       Billing Information
                </span>
            </header>
            </div>
                )
            }
        }
}

const shippingInfo = () => {
        if (props.states !== undefined) {
            switch (cardSuccess){
                case null: return
                break;
                case undefined: return
                break;
                case "": return
                break;
                case true:
                    switch (inputText.pickupShip) {
                      case "Pickup": return
                        break;
                      case "Ship":
                            switch(shippingState){
                                case true: return(
                           <>
                                {shippingComplete()}
                             <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0" style={{"marginBottom":"40px"}} >
                               <span className="wsite-button-inner">
                                 <div className="wsite-next-button-label" onClick={()=>setShippingState(!shippingState)}>Update</div>
                               </span>
                             </button>
                           </>
                        )
                                    break;
                                    case false:
                                    
                        return (
                           <>
                             {shipping()}
                             <section className="wsite-checkout-payment__section wsite-checkout-proceed js-shipping-information-actions">
                              <fieldset className="wsite-checkout-form__fieldset">
                                <div className="wsite-checkout-spinner wsite-checkout-spinner--small is-hidden js-checkout-spinner"></div>
                                <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0">
                                  <span className="wsite-button-inner">
                                      <div className={`wsite-next-button-label ${spinner ? "loading" : ""}`} onClick={()=>{validation2()}}>Next</div> 
                                  </span>
                                </button>
                              </fieldset>
                            </section>
                           </>
                        )
                                    break;
                            }
                    break;
                }
                break;
                default: 
                    switch (inputText.pickupShip) {
                      case "Pickup": return
                      break;
                        case "Ship":    
                    return (
                <div class="wsite-checkout-payment__step" style={{"display": "block"}}><header class="wsite-checkout-payment__header wsite-checkout-payment__container"><span class="js-payment-header-title wsite-checkout-payment__header-title">
	       Shipping Information
                </span>
            </header>
            </div>
                )
                break;
                        default: return
            }
            }
        }
}
 const createMember = async () => {
     if (props.uid !== null) {
     app.callFunction("SQUARE_Customers", ["createMember", {
                clubId: props.uid,
                sq_refId: customerId,
                pickupShip: inputText.pickupShip,
                shipAddress: inputTextShipping 
              }]).then(result => {
                switch (result.status) {
                  case 400:
                    alert('Uh Oh! Something seems to have gone wrong connecting to Square.  If this continues, please try logging out and back in again.');
                    break;
                  case 410:
                    alert('ERROR: ' + result.text); //Vincipia error based on mongo return
                    break;
                  case 200:{
                      
                        setSpinner(false)
                      setCreateMemberState(true)
                        //change createMemberState to true which activates the receipt to be displayed
                  }
                      
                    break;
                  default:
                    alert('Uh Oh! Something seems to have gone wrong.  Please try again.')
                }
              })
              .catch(err => alert('Uh Oh! Something seems to have gone wrong connecting to the server.  Please try again.'))
     }
 }    
 const submitForm = async () => {
 
  if (props.uid !== null) {
      setSpinner(!spinner);
        switch (customerId) {
          case undefined:
            app.callFunction("SQUARE_Customers", ["createCustomer", {
                clubId: props.uid,
                formData: inputText
              }]).then(result => {
                switch (result.status) {
                  case 400:
                    alert('Uh Oh! Something seems to have gone wrong connecting to Square.  If this continues, please try logging out and back in again.');
                    break;
                  case 410:
                    alert('ERROR: ' + result.text); //Vincipia error based on mongo return
                    break;
                  case 200:
                      setCustomerId(result.data.customer.id) // customer ID from Square to pass to Square COF API
                      setBillingState(true)
                      setSpinner(false);
                    break;
                  default:
                    alert('Uh Oh! Something seems to have gone wrong.  Please try again.')
                }
              })
              .catch(err => alert('Uh Oh! Something seems to have gone wrong connecting to the server.  Please try again.'))
            break;
          default:
            app.callFunction("SQUARE_Customers", ["updateCustomer", {
                clubId: props.uid,
                formData: inputText,
                sq_refId: customerId
              }]).then(result => {
                switch (result.status) {
                  case 400:
                    alert('Uh Oh! Something seems to have gone wrong connecting to Square.  If this continues, please try logging out and back in again.');
                    break;
                  case 410:
                    alert('ERROR: ' + result.text); //Vincipia error based on mongo return
                    break;
                  case 200:
                    setBillingState(true)
                    setSpinner(false);
                    break;
                  default:
                    alert('Uh Oh! Something seems to have gone wrong.  Please try again.')
                }
              })
              .catch(err => alert('Uh Oh! Something seems to have gone wrong connecting to the server.  Please try again.'))
        }
    
  

}
    }
 
    const billingWithId = () => { // when a customer id has been created display this
        return (
        <>
            <header className="wsite-checkout-payment__header">
                <div className="wsite-checkout-payment__container">
                  <div className="js-address-header wsite-checkout-payment__header-title">
                    Billing Information
                  </div>
                </div>
              </header>
            <span className="wsite-checkout-form__label">Email Address: <span style={{"fontWeight": "100"}}>{inputText.email}</span></span>
            <span className="wsite-checkout-form__label">Name: <span style={{"fontWeight": "100"}}>{inputText.given_name} {inputText.family_name}</span></span>
            <span className="wsite-checkout-form__label">Address:</span>
            <span className="wsite-checkout-form__label" style={{"fontWeight": "100"}}>{inputText.address_line_1}</span>
            <span className="wsite-checkout-form__label" style={{"fontWeight": "100"}}>{inputText.address_line_2}</span>
            <span className="wsite-checkout-form__label" style={{"fontWeight": "100"}}>{inputText.locality}, {inputText.administrative_district_level_1}</span>
            <span className="wsite-checkout-form__label" style={{"fontWeight": "100"}}>{inputText.postal_code}</span>
            <span className="wsite-checkout-form__label">Phone: <span style={{"fontWeight": "100", "marginBottom":"10px"}}>{inputText.phone_number}</span> </span>
            
        </>
        
        )
    }
 
    


    const billingNoId = () => { // when no customer id has been created show form
        return (
            <>
                    <div className="wsite-checkout-payment__content">
            <div id="customer-accounts-app"></div>
            <section className="wsite-checkout-payment__section js-shipping-information-form">
              <header className="wsite-checkout-payment__header">
                <div className="wsite-checkout-payment__container">
                  <div className="js-address-header wsite-checkout-payment__header-title">
                    Billing Information
                  </div>
                </div>
              </header>
              <div className="wsite-checkout-payment__content">
                <form className="js-address-form wsite-checkout-form wsite-checkout-payment__form">
            <fieldset className="wsite-checkout-form__fieldset">
                  <div className="wsite-checkout-form__row">
                    <label className="wsite-checkout-form__field js-customer-email-label">
                      <span className="wsite-checkout-form__label">Email Address</span>
                      <div className="wsite-checkout-form__input wsite-checkout-form__email-input-wrapper js-checkout-form__email-input-modal-attach">
                        <input className="js-customer-email wsite-checkout-form__email-input is-invalid" type="text" name="email" value={inputText.email} onChange={handleInput} autocapitalize="off" autocomplete="email">
                        </input>
                        <span className="wsite-checkout-form__email-enter-code js-customer-email-enter-code">Enter code</span>
                      </div>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="email">{error.email}</div>
                      
                    </label>
                  </div>
                </fieldset>
                  <fieldset className="wsite-checkout-form__fieldset">
                    <div className="wsite-checkout-form__row">
                      <label className="wsite-checkout-form__field">
                        <span className="wsite-checkout-form__label">First Name</span>
                        <input className="wsite-checkout-form__input" type="text" name="given_name" value={inputText.given_name} onChange={handleInput} autocomplete="given-name"></input>
                        <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="given_name">{error.given_name}</div>
                      </label>
                      <label className="wsite-checkout-form__field">
                        <span className="wsite-checkout-form__label">Last Name</span>
                        <input className="wsite-checkout-form__input" type="text" name="family_name" value={inputText.family_name} onChange={handleInput} autocomplete="family-name"></input>
                        <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="family_name">{error.family_name}</div>
                      </label>
                    </div>
                    <div className="wsite-checkout-form__row">
                      <label className="wsite-checkout-form__field">
                        <span className="wsite-checkout-form__label">Address Line 1</span>
                        <input className="wsite-checkout-form__input" type="text" name="address_line_1" value={inputText.address_line_1} onChange={handleInput} ></input>
                        <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="address_line_1">{error.address_line_1}</div>
                      </label>
                    </div>
                    <div className="wsite-checkout-form__row">
                      <label className="wsite-checkout-form__field">
                        <span className="wsite-checkout-form__label">Address Line 2</span>
                        <input className="wsite-checkout-form__input" type="text" name="address_line_2" value={inputText.address_line_2} onChange={handleInput}></input>
                        <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="address_line_2">{error.address_line_2}</div>
                      </label>
                    </div>
                    <div className="wsite-checkout-form__row">
                      <label className="wsite-checkout-form__field">
                        <span className="wsite-checkout-form__label">City</span>
                        <input className="wsite-checkout-form__input" type="text" name="locality" value={inputText.locality} onChange={handleInput} ></input>
                        <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="locality">{error.locality}</div>
                      </label>
                    </div>
                    <div className="wsite-checkout-form__row ">
                      <label className="wsite-checkout-form__field wsite-checkout-form__field--state"> <span className="js-state-select-label wsite-checkout-form__label">State</span>
                        <div className="wsite-checkout-form__select-wrap">
                        {allStates()}
                        </div>
                        <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="administrative_district_level_1">{error.administrative_district_level_1}</div>
                      </label>
                      <label className="wsite-checkout-form__field wsite-checkout-form__field--zipcode">
                        <span className="js-zip-code-label wsite-checkout-form__label">Zip Code</span>
                        <input className="js-zip-code-input wsite-checkout-form__input" type="text" name="postal_code" value={inputText.postal_code} onChange={handleInput} autocomplete="shipping postal-code"></input>
                        <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="postal_code">{error.postal_code}</div>
                      </label>
                    </div>
                    <div className="wsite-checkout-form__row">
                      <label className="wsite-checkout-form__field">
                        <span className="wsite-checkout-form__label">Country</span>
                        <div className="wsite-checkout-form__select-wrap">
                          <select className="js-shipping-address-country wsite-checkout-form__select" name="country" value={inputText.country} onChange={handleInput} autocomplete="shipping country">
                            <option value="US" selected="">
                              United States
                            </option>
                          </select>
                        </div>
                        <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="country"></div>
                      </label>
                    </div>
                    <div className="wsite-checkout-form__row ">
                      <label className="wsite-checkout-form__field wsite-checkout-form__field--phone_number-country-code wsite-checkout-js-checkout-phone_number-country-code">
                        <span className="wsite-checkout-form__label">
                          Phone Number
                        </span>
                        <div className="wsite-checkout-form__select-wrap">
                          <select className="wsite-checkout-form__select js-phone_number-country-code" name="phone_number_country_code" value={inputText.phone_number_country_code} onChange={handleInput}>
                            <option selected="" value="US">
                              United States +1
                            </option>
                          </select>
                        </div>
                      </label>
                      <label className="wsite-checkout-form__field wsite-checkout-form__field--phone_number wsite-checkout-js-checkout-phone_number-number ">
                        <span className="wsite-checkout-form__label">&nbsp;</span>
                        <input className="wsite-checkout-form__input" type="text" name="phone_number" value={inputText.phone_number} onChange={handleInput} autocomplete="tel"></input>
                        <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="phone_number">{error.phone_number}</div>
                      </label>
                    </div>
                  </fieldset>
                </form>
              </div>
              <div className="js-read-only-address-administrative_district_level_1"></div>
              <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="shippingAddressGenericError"></div>
            </section>
            <section className="wsite-checkout-payment__section wsite-checkout-proceed js-shipping-information-actions">
              <fieldset className="wsite-checkout-form__fieldset">
                <div className="wsite-checkout-spinner wsite-checkout-spinner--small is-hidden js-checkout-spinner"></div>
                <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0" style={{"marginBottom":"40px"}}>
                  <span className="wsite-button-inner">
                    <div className={`wsite-next-button-label ${spinner ? "loading" : ""}`} onClick={()=>{validation()}}>Next</div> 
                  </span>
                </button>
              </fieldset>
            </section>
          </div>
    </>
        )
        
        
        
    }
    
    
    
const shipping = () => { // when no customer id has been created show form
        return (
            <>
                    <div className="wsite-checkout-payment__content">
            
            <div id="customer-accounts-app"></div>
            <section className="wsite-checkout-payment__section js-shipping-information-form">
              <header className="wsite-checkout-payment__header">
                <div className="wsite-checkout-payment__container">
                  <div className="js-address-header wsite-checkout-payment__header-title">
                    Shipping Information
                  </div>
                </div>
              </header>
              <div className="wsite-checkout-payment__content">
                  
                  <label className="wsite-checkout-form__field js-customer-email-label" style={{"marginBottom":"20px"}}>
                      <span className="wsite-checkout-form__label"><input type="checkbox" onClick={handleInputShippingSameAsBilling}></input>  &nbsp; Set Shipping Same as Billing</span>
                  </label>
                <form className="js-address-form wsite-checkout-form wsite-checkout-payment__form">
            <fieldset className="wsite-checkout-form__fieldset">
                  <div className="wsite-checkout-form__row">
                    <label className="wsite-checkout-form__field js-customer-email-label">
                      <span className="wsite-checkout-form__label">Email Address</span>
                      <div className="wsite-checkout-form__input wsite-checkout-form__email-input-wrapper js-checkout-form__email-input-modal-attach">
                        <input className="js-customer-email wsite-checkout-form__email-input is-invalid" type="text" name="email" value={inputTextShipping.email} onChange={handleInputShipping} autocapitalize="off" autocomplete="email">
                        </input>
                      </div>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="email">{error.emailShipAddress}</div>
                      <div id="customer-accounts-payment-login"></div>
                    </label>
                  </div>
                </fieldset>
                  <fieldset className="wsite-checkout-form__fieldset">
                    <div className="wsite-checkout-form__row">
                      <label className="wsite-checkout-form__field">
                        <span className="wsite-checkout-form__label">First Name</span>
                        <input className="wsite-checkout-form__input" type="text" name="given_name" value={inputTextShipping.given_name} onChange={handleInputShipping} autocomplete="given-name"></input>
                        <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="given_name">{error.given_nameShipAddress}</div>
                      </label>
                      <label className="wsite-checkout-form__field">
                        <span className="wsite-checkout-form__label">Last Name</span>
                        <input className="wsite-checkout-form__input" type="text" name="family_name" value={inputTextShipping.family_name} onChange={handleInputShipping} autocomplete="family-name"></input>
                        <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="family_name">{error.family_nameShipAddress}</div>
                      </label>
                    </div>
                    <div className="wsite-checkout-form__row">
                      <label className="wsite-checkout-form__field">
                        <span className="wsite-checkout-form__label">Address Line 1</span>
                        <input className="wsite-checkout-form__input" type="text" name="address_line_1" value={inputTextShipping.address_line_1} onChange={handleInputShipping} ></input>
                        <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="address_line_1">{error.address_line_1ShipAddress}</div>
                      </label>
                    </div>
                    <div className="wsite-checkout-form__row">
                      <label className="wsite-checkout-form__field">
                        <span className="wsite-checkout-form__label">Address Line 2</span>
                        <input className="wsite-checkout-form__input" type="text" name="address_line_2" value={inputTextShipping.address_line_2} onChange={handleInputShipping}></input>
                        <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="address_line_2"></div>
                      </label>
                    </div>
                    <div className="wsite-checkout-form__row">
                      <label className="wsite-checkout-form__field">
                        <span className="wsite-checkout-form__label">City</span>
                        <input className="wsite-checkout-form__input" type="text" name="locality" value={inputTextShipping.locality} onChange={handleInputShipping} ></input>
                        <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="locality">{error.localityShipAddress}</div>
                      </label>
                    </div>
                    <div className="wsite-checkout-form__row ">
                    <label className="wsite-checkout-form__field wsite-checkout-form__field--state"> <span className="js-state-select-label wsite-checkout-form__label">Please Select Your State <small>(Only states we ship to will be visable)</small></span>
                      <div className="wsite-checkout-form__select-wrap">
                        <select className="js-state-selection wsite-checkout-form__select" name="administrative_district_level_1" value={inputTextShipping.administrative_district_level_1} onChange={handleInputShipping}>
                          <option></option>
                          {props.states.map((data, index) => (
                          <option value={data.name}>{data.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="administrative_district_level_1">{error.administrative_district_level_1ShipAddress}</div>
                    </label>
                      <label className="wsite-checkout-form__field wsite-checkout-form__field--zipcode">
                        <span className="js-zip-code-label wsite-checkout-form__label">Zip Code</span>
                        <input className="js-zip-code-input wsite-checkout-form__input" type="text" name="postal_code" value={inputTextShipping.postal_code} onChange={handleInputShipping} autocomplete="shipping postal-code"></input>
                        <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="postal_code">{error.postal_codeShipAddress}</div>
                      </label>
                    </div>
                    <div className="wsite-checkout-form__row">
                      <label className="wsite-checkout-form__field">
                        <span className="wsite-checkout-form__label">Country</span>
                        <div className="wsite-checkout-form__select-wrap">
                          <select className="js-shipping-address-country wsite-checkout-form__select" name="country" value={inputTextShipping.country} onChange={handleInputShipping} autocomplete="shipping country">
                            <option value="US" selected="">
                              United States
                            </option>
                          </select>
                        </div>
                        <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="country"></div>
                      </label>
                    </div>
                    <div className="wsite-checkout-form__row ">
                      <label className="wsite-checkout-form__field wsite-checkout-form__field--phone_number-country-code wsite-checkout-js-checkout-phone_number-country-code">
                        <span className="wsite-checkout-form__label">
                          Phone Number
                        </span>
                        <div className="wsite-checkout-form__select-wrap">
                          <select className="wsite-checkout-form__select js-phone_number-country-code" name="phone_number_country_code" value={inputTextShipping.phone_number_country_code} onChange={handleInputShipping}>
                            <option selected="" value="US">
                              United States +1
                            </option>
                          </select>
                        </div>
                      </label>
                      <label className="wsite-checkout-form__field wsite-checkout-form__field--phone_number wsite-checkout-js-checkout-phone_number-number ">
                        <span className="wsite-checkout-form__label">&nbsp;</span>
                        <input className="wsite-checkout-form__input" type="text" name="phone_number" value={inputTextShipping.phone_number} onChange={handleInputShipping} autocomplete="tel"></input>
                        <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="phone_number">{error.phone_numberShipAddress}</div>
                      </label>
                    </div>
                  </fieldset>
                </form>
              </div>
              <div className="js-read-only-address-administrative_district_level_1"></div>
              <div className="js-form-invalid-text wsite-checkout-form__invalid-text" data-for="shippingAddressGenericError"></div>
            </section>
            
          </div>
    </>
        )
        
        
        
    }
    
    
const paymentSelect = () => {
        switch (customerId) {
            case null: return;
            break;
            case undefined: return (
            <div class="wsite-checkout-payment__step" style={{"display": "block"}}><header class="wsite-checkout-payment__header wsite-checkout-payment__container"><span class="js-payment-header-title wsite-checkout-payment__header-title">
	       Payment Information
                </span>
            </header>
            </div>
            )
            break;
            default:
                switch (cardSuccess){
                    case false:
                return ( <>
                        <header className="wsite-checkout-payment__header">
                <div className="wsite-checkout-payment__container">
                  <div className="js-address-header wsite-checkout-payment__header-title">
                    Payment Information
                  </div>
                </div>
              </header>
                        <PaymentPage customerId={customerId} uid={props.uid} onChange={handleCard}/>
                        
                        <p style={{"marginBottom":"40px"}}>
                        By clicking the Confirm Button I, {inputText.given_name} {inputText.family_name}, authorize {props.prodName} to charge my credit card above for agreed upon purchases related to my club membership. I understand that my information will be saved to file for future transactions on my account.
                        </p>
                        </>
                        )
                        break;
                    case true:
                        return (
                            <>
                            <header className="wsite-checkout-payment__header">
                <div className="wsite-checkout-payment__container">
                  <div className="js-address-header wsite-checkout-payment__header-title">
                    Payment Information Added
                  </div>
                </div>
              </header>
                            </>
                        )
                    default: return
            }
        }
    
}


const shippingSelect = () => {
    if (shippingState === false) {
       return 
    }
    else if (shippingState === true) {
        return (
       <>
           
           <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0">
                  <span className="wsite-button-inner">
                    <div className={`wsite-next-button-label ${spinner ? "loading" : ""}`} onClick={()=>{setBillingState(!billingState)}}>Update</div> 
                  </span>
                </button>
           
           </>
           )
}
}

const reviewSubmit = () => {
    if (props.states !== undefined) {
        switch(inputText.pickupShip){
            case "Ship":{
            switch (shippingState){
                case null: return
                break;
                case undefined: return
                break;
                case "": return
                break;
                case true:{
                  return (
                        <>
                            <section className="wsite-checkout-payment__section wsite-checkout-proceed js-shipping-information-actions">
                              <fieldset className="wsite-checkout-form__fieldset">
                                <div className="wsite-checkout-spinner wsite-checkout-spinner--small is-hidden js-checkout-spinner"></div>
                                <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0" style={{"marginRight": "10px"}}>
                                  <span className="wsite-button-inner">
                                    <div className={`wsite-next-button-label ${spinner ? "loading" : ""}`} onClick={()=>{cancelCustomer()}}>Cancel</div>
                                  </span>
                                </button>
                                <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0">
                                  <span className="wsite-button-inner">
                                    <div className="wsite-next-button-label" onClick={createMember}>Sign Up</div>
                                  </span>
                                </button>
                              </fieldset>
                            </section>
                            </>
                        )
                    }
                break;
                default:
                    return (
                            <div class="wsite-checkout-payment__step" style={{"display": "block"}}><header class="wsite-checkout-payment__header wsite-checkout-payment__container"><span class="js-payment-header-title wsite-checkout-payment__header-title">
                       Review and Subscribe
                            </span>
                        </header>
                        </div>
                    )
            }
            }
        break;  
        case "Pickup": {
            switch (cardSuccess){
                case null: return
                break;
                case undefined: return
                break;
                case "": return
                break;
                case true:{
                  return (
                        <>
                            <section className="wsite-checkout-payment__section wsite-checkout-proceed js-shipping-information-actions">
                              <fieldset className="wsite-checkout-form__fieldset">
                                <div className="wsite-checkout-spinner wsite-checkout-spinner--small is-hidden js-checkout-spinner"></div>
                                <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0" style={{"marginRight": "10px"}}>
                                  <span className="wsite-button-inner">
                                    <div className={`wsite-next-button-label ${spinner ? "loading" : ""}`} onClick={()=>{cancelCustomer()}}>Cancel</div>
                                  </span>
                                </button>
                                <button className="js-next-btn wsite-button wsite-button-small wsite-button-highlight wsite-buy-button" tabindex="0">
                                  <span className="wsite-button-inner">
                                    <div className="wsite-next-button-label" onClick={createMember}>Sign Up</div>
                                  </span>
                                </button>
                              </fieldset>
                            </section>
                            </>
                        )
                    }
                break;
                default:
                    return (
                            <div class="wsite-checkout-payment__step" style={{"display": "block"}}><header class="wsite-checkout-payment__header wsite-checkout-payment__container"><span class="js-payment-header-title wsite-checkout-payment__header-title">
                       Review and Subscribe
                            </span>
                        </header>
                        </div>
                    )
            }
        }
        break;
    
        }
    }
}

const shippingComplete = () => { // when a customer id has been created display this
        return (
        <>
            <header className="wsite-checkout-payment__header">
                <div className="wsite-checkout-payment__container">
                  <div className="js-address-header wsite-checkout-payment__header-title">
                    Shipping Information
                  </div>
                </div>
              </header>
            <span className="wsite-checkout-form__label">Email Address: <span style={{"fontWeight": "100"}}>{inputTextShipping.email}</span></span>
            <span className="wsite-checkout-form__label">Name: <span style={{"fontWeight": "100"}}>{inputTextShipping.given_name} {inputTextShipping.family_name}</span></span>
            <span className="wsite-checkout-form__label">Address:</span>
            <span className="wsite-checkout-form__label" style={{"fontWeight": "100"}}>{inputTextShipping.address_line_1}</span>
            <span className="wsite-checkout-form__label" style={{"fontWeight": "100"}}>{inputTextShipping.address_line_2}</span>
            <span className="wsite-checkout-form__label" style={{"fontWeight": "100"}}>{inputTextShipping.locality}, {inputTextShipping.administrative_district_level_1}</span>
            <span className="wsite-checkout-form__label" style={{"fontWeight": "100"}}>{inputTextShipping.postal_code}</span>
            <span className="wsite-checkout-form__label">Phone: <span style={{"fontWeight": "100", "marginBottom":"10px"}}>{inputTextShipping.phone_number}</span> </span>
            
        </>
        
        )
    }

const ticketOrReceipt = () => {
    switch(createMemberState){
        case true: return <Receipt billingInfo={inputText} shippingInfo={inputTextShipping} customerInfo={customerId}/>
        break;
        default:
            return (
                <>
                {deliveryOptions()}
                {states()}
                {billingInfo()}
                {paymentSelect()}
                {shippingInfo()}
                {reviewSubmit()}
                </>
            )
    }
}

return (
        <>
        {ticketOrReceipt()}
        </>
    )
}