import React, {
  useState,
  useEffect
} from "react";
import {
  useStitchAuth
} from "../App/StitchAuth";
import { app } from "../MongoStitch/app";
import PaymentPage  from "../Square/paymentPage"


export default function Receipt(props) {
    
    

let newDate = new Date()
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();


const shippingSection = () => {
    switch(props.billingInfo.pickupShip){
            case "Ship": return (
            <div className="col-12 col-md-6">
            <header className="wsite-checkout-payment__header">
                <div className="wsite-checkout-payment__container">
                  <div className="js-address-header wsite-checkout-payment__header-title">
                    Shipping Information
                  </div>
                </div>
              </header>
            <span className="wsite-checkout-form__label">Email Address: <span style={{"fontWeight": "100"}}>{props.shippingInfo.email}</span></span>
            <span className="wsite-checkout-form__label">Name: <span style={{"fontWeight": "100"}}>{props.shippingInfo.given_name} {props.shippingInfo.family_name}</span></span>
            <span className="wsite-checkout-form__label">Address:</span>
            <span className="wsite-checkout-form__label" style={{"fontWeight": "100"}}>{props.shippingInfo.address_line_1}</span>
            <span className="wsite-checkout-form__label" style={{"fontWeight": "100"}}>{props.shippingInfo.address_line_2}</span>
            <span className="wsite-checkout-form__label" style={{"fontWeight": "100"}}>{props.shippingInfo.locality}, {props.shippingInfo.administrative_district_level_1}</span>
            <span className="wsite-checkout-form__label" style={{"fontWeight": "100"}}>{props.shippingInfo.postal_code}</span>
            <span className="wsite-checkout-form__label">Phone: <span style={{"fontWeight": "100", "marginBottom":"10px"}}>{props.shippingInfo.phone_number}</span> </span>
    </div>
            )
            break;
        default: return;
    }
}
    

return (
    <>
    <div className="row" align="center">
    <div className="col">
    <span><h1>RECEIPT</h1></span>
    <p>Please print this page for your records</p>
    </div>
    </div>
    <div className="row" style={{"marginTop":"20px", "marginBottom":"40px"}}>
    <div className="col">
    <h4>You have successfully subscribed as a member to the club!</h4>
    <div><b>Customer Number: </b>{props.customerInfo}</div>
    <p><b>Member Since:</b> {month<10?`0${month}`:`${month}`}/{date}/{year}</p>
    </div>
    </div>
   
    <div className="row">
    <div className="col-12 col-md-6">
            <header className="wsite-checkout-payment__header">
                <div className="wsite-checkout-payment__container">
                  <div className="js-address-header wsite-checkout-payment__header-title">
                    Billing Information
                  </div>
                </div>
              </header>
            <span className="wsite-checkout-form__label">Email Address: <span style={{"fontWeight": "100"}}>{props.billingInfo.email}</span></span>
            <span className="wsite-checkout-form__label">Name: <span style={{"fontWeight": "100"}}>{props.billingInfo.given_name} {props.billingInfo.family_name}</span></span>
            <span className="wsite-checkout-form__label">Address:</span>
            <span className="wsite-checkout-form__label" style={{"fontWeight": "100"}}>{props.billingInfo.address_line_1}</span>
            <span className="wsite-checkout-form__label" style={{"fontWeight": "100"}}>{props.billingInfo.address_line_2}</span>
            <span className="wsite-checkout-form__label" style={{"fontWeight": "100"}}>{props.billingInfo.locality}, {props.billingInfo.administrative_district_level_1}</span>
            <span className="wsite-checkout-form__label" style={{"fontWeight": "100"}}>{props.billingInfo.postal_code}</span>
            <span className="wsite-checkout-form__label">Phone: <span style={{"fontWeight": "100", "marginBottom":"10px"}}>{props.billingInfo.phone_number}</span> </span>
    </div>
    {shippingSection()}
    </div>
    </>
        )
}