import React, {useState,useEffect} from "react";
import {StitchAuthProvider,useStitchAuth} from "../App/StitchAuth";
import ClubSignup from '../ClubSignup';
import Admin from '../Admin';

export default function DataSwitch() {

  const {isLoggedIn} = useStitchAuth();
  useEffect(() => {
    
  }, []);
    //if is logged in call db to get member info
    //else dont
    
  return (// true | false
    <div> {isLoggedIn ? <Admin /> : <ClubSignup />} </div>
  );
    
}