import React, { useState, useEffect } from "react";
import { useStitchAuth } from "../App/StitchAuth";
import { app } from "../MongoStitch/app";
import BillingInfo from '../SignUp'
import { BSON, UserPasswordAuthProviderClient } from 'mongodb-stitch-browser-sdk';
import { clients } from "../MongoStitch";
import ConsumerSignUp from "../Login/SignUpUser"
import {
  newPasswordReset, resetPassword
} from "../MongoStitch/authentication";

export default function Login(props) {
  const { actions } = useStitchAuth();
  const [queryString, setQueryString] = useState(window.location.search);
  const [urlParams, setUrlParams] = useState(new URLSearchParams(queryString));
  const [token, setToken] = useState(urlParams.get('token'));
  const [tokenId, setTokenId] = useState(urlParams.get('tokenId'));
  const [type, setType] = useState(urlParams.get('type'));
  const [username, setUsername] = useState(urlParams.get('username'));
  const [password, setPassword] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const provider = 'email';
  const [error, setError] = useState('');
  const [createAcctError, setCreateAcctError] = useState('');
  const [loginKey, setloginKey] = useState('');
  const [firstLogin, setFirstLogin] = useState(false);
  const [setOption, setSetOption] = useState('');
  const [menu, setMenu] = useState(false);
  const [condition, setCondition] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (token !== null && tokenId !== null) {
      window.history.pushState("object or string", "Title", "/");
      switch (type) {
        case "register": { confirmUser(); }
          break;
        case "reset": { setSetOption('reset'); passwordNewReset(); }
          break;
        default: { confirmUser(); }
      }
    }
  }, []);

  function handleChange() {
    if (menu === true) {
      setMenu(!menu)
      setCondition(!condition)
      setModal(!modal);
    }
    else {
      setModal(!modal);
    }
  }

  const passwordNewReset = async () => {
    setloginKey('inProgress')
    const emailPasswordClient = app.auth
      .getProviderClient(UserPasswordAuthProviderClient.factory);
    emailPasswordClient.resetPassword(token, tokenId, password)
      .then(result => {
        alert("Successfully reset password!")
        setUsername('')
        setSetOption('')
        setloginKey('')
      })
      .catch(err => {
        console.log(err.message)
        switch (err.message) {
          case "expected 'username' to be a string": setError("invalid email")
            break;
          case "user not found": setError("Email address not found.  If you recently created your account this may be due to not confirming through the confirmation email sent to you.")
            break;
          default: setError(err.message)
        }

      })
  }
  const passwordResetTokenReq = async () => {
    setloginKey('inProgress')
    const emailPasswordClient = app.auth
      .getProviderClient(UserPasswordAuthProviderClient.factory);
    emailPasswordClient.sendResetPasswordEmail(username)
      .then(result => {
        setloginKey('')
        alert("Successfully sent password reset email!")
        setSetOption('')
      })
      .catch(err => {
        switch (err.message) {
          case "expected 'username' to be a string": setError("invalid email")
            break;
          case "user not found": setError("Email address not found.    If you recently created your account, this may be due to not confirming through the confirmation email sent to you.")
            break;
          default: setError(err.message)
        }

      })
  }

  const resendConfirm = () => {

    const emailPasswordClient = app.auth
      .getProviderClient(UserPasswordAuthProviderClient.factory);
    emailPasswordClient.resendConfirmationEmail(username)
      .then(result => {
        alert("Successfully resent email!")
      }
      )
      .catch(err => {
        switch (err.message) {
          case "expected 'username' to be a string": setError("invalid email")
            break;
          case "user not found": setError("Email address not found.")
            break;
          default: setError(err.message)
        }

      })
  }

  const confirmUser = () => {
    const emailPasswordClient = app.auth
      .getProviderClient(UserPasswordAuthProviderClient.factory);
    emailPasswordClient.confirmUser(token, tokenId)
      .then(result => {
        alert('Account confirmed! Please Log in.')
      })
      .catch(err => {
        switch (err.message) {
          case "expected 'username' to be a string": setError("invalid email")
            break;
          default: setError(err.message)
        }

      })
  }

  const loginOption = () => {
    //not a reset request
    switch (setOption) {
      case '': return (
        <>

          <div className="form-row justify-content-center" style={{ "border": "none" }}>
            <div className="col mb-3">
              <input autoCapitalize="off" autoComplete="off" autoCorrect="off" autoFocus="autofocus" name="email" type="email" placeholder="email address" value={username} onChange={(event) => setUsername(event.target.value)} spellCheck="false" className="form-control" id="validationCustom03" />
            </div>
          </div>
          <div className="form-row justify-content-center" style={{ "border": "none" }}>
            <div className="col mb-3">
              <input autoCapitalize="off" autoComplete="off" autoCorrect="off" autoFocus="autofocus" name="password" type="password" placeholder="password" value={password} onChange={(event) => setPassword(event.target.value)} spellCheck="false" className="form-control" id="validationCustom05" />
              <div className="invalid-feedback" style={{ "display": "revert", marginBottom: "12px", color: "red" }}>
                {error}
              </div>
            </div>
          </div>

          <button className="vinButton" style={{ "marginLeft": "12px" }} onClick={
            () => {
              actions.handleLogin({
                username,
                password,
                provider,
                firstLogin
              }).then(function (result) {
                if (result !== undefined) {
                  switch (result.message) {
                    case "expected 'username' to be a string":
                      setError("invalid email address")
                      break;
                    default:
                      setError(result.message)
                  }
                  setloginKey('')
                }
              })
                .then(setloginKey('loggedIn'))
            }
          }
          >Login</button>
          <button className="vinButton" style={{ "float": "right", "marginRight": "12px" }} onClick={handleChange}>Create an Account</button>
          <div className="row">
            <a style={{ "fontSize": ".6rem", "marginLeft": "22px", "marginTop": "20px" }} onClick={() => { setSetOption("pwReset") }}>Forgot Password?</a>
            <a style={{ "fontSize": ".6rem", "marginLeft": "22px", "marginTop": "20px" }} onClick={() => { setSetOption("resendConfEmail") }}>ReSend Confirmation</a>
          </div>
        </>
      )
      case 'reset': return (
        <>

          <h3>Create New Password</h3>
          <small>Enter a new password for your account login</small>
          <div className="form-row justify-content-center" style={{ "border": "none" }}>
            <div className="col mb-3">
              <input autoCapitalize="off" autoComplete="off" autoCorrect="off" autoFocus="autofocus" name="password" type="password" placeholder="enter new password" value={password} onChange={(event) => setPassword(event.target.value)}
                spellCheck="false" className="form-control" id="validationCustom05" required />

            </div>
          </div>
          <div className="invalid-feedback" style={{ "display": "revert", marginBottom: "12px", color: "red" }}>
            {error}
          </div>


          <button className="vinBtn" onClick={passwordNewReset}
          >reset</button>
        </>
      )
      case 'resendConfEmail': return (
        <>

          <h3>Resend Confirmation Email</h3>
          <small>If you need to have your confirmation email resent because it has expired please enter your email below</small>
          <div className="form-row justify-content-center" style={{ "border": "none" }}>
            <div className="col mb-3">
              <input autoCapitalize="off" autoComplete="off" autoCorrect="off" autoFocus="autofocus" name="email" type="email" placeholder="email address" value={username} onChange={(event) => setUsername(event.target.value)}
                spellCheck="false" className="form-control" id="validationCustom03" required />

            </div>
          </div>
          <div className="invalid-feedback" style={{ "display": "revert", marginBottom: "12px", color: "red" }}>
            {error}
          </div>

          <div className="row">
            <div className="col-12" align="center">
              <button className="vinBtn" onClick={resendConfirm}
              >resend confirmation</button>
            </div>
          </div>
          <div className="row">
            <div className="col-12" align="center" style={{ "marginTop": "20px" }}>
              <a onClick={() => { setSetOption(''); setUsername(''); setError('') }}
              >back to login</a>
            </div>
          </div>

        </>
      )
      case 'pwReset': return (
        <>

          <h3>Reset Password</h3>
          <small>Enter the email address you used to register with VinSipp and we'll email you instructions to reset your password.</small>
          <div className="form-row justify-content-center" style={{ "border": "none" }}>
            <div className="col mb-3">
              <input autoCapitalize="off" autoComplete="off" autoCorrect="off" autoFocus="autofocus" name="email" type="email" placeholder="email address" value={username} onChange={(event) => setUsername(event.target.value)}
                spellCheck="false" className="form-control" id="validationCustom03" required />

            </div>
          </div>
          <div className="invalid-feedback" style={{ "display": "revert", marginBottom: "12px", color: "red" }}>
            {error}
          </div>


          <div className="row">
            <div className="col-12" align="center">
              <button className="vinBtn" onClick={passwordResetTokenReq}
              >send instructions</button>
            </div>
          </div>
          <div className="row">
            <div className="col-12" align="center" style={{ "marginTop": "20px" }}>
              <a onClick={() => { setSetOption(''); setUsername(''); setError('') }}
              >back to login</a>
            </div>
          </div>
        </>


      )
    }
  }
  return (
    <>

      <div className="container-fluid" style={{ "height": "100vh", "background-color": "rgb(235, 237, 239)" }}>
        <div className="row justify-content-center" style={{ "height": "100%" }}>
          <div className="col-xs-12 col-sm-8 col-md-6 col-lg-5 align-self-center" style={{ "border": "1px solid #ccc", "padding": "60px", "background-color": "rgb(255, 255, 255)" }}>
            <div className="row justify-content-center align-items-center no-gutters" >
              <h1 align="center">VinSipp</h1>
              <p>A system to manage all of your club subscriptions across participating craft producers and retailers all in one place</p>
            </div>
            {loginOption()}
            <div className="row justify-content-center align-items-center no-gutters">
              <div align="center"><small>Powered by Vincipia</small></div>
            </div>
          </div>

        </div>
      </div>
      <ConsumerSignUp modal={modal} onChange={handleChange} />

    </>
  );
}